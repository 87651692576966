/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import config from 'config';
import { ReactComponent as EvoNexusLogo } from 'assets/img/EvoNexusLogo.svg';

import { Section, Container, Columns } from '../Layout';
import FollowUs from '../FollowUs';

import styles from './index.module.scss';
import { segmentsFooterTrack } from '../../utils/trackEvents';
import { URLS_DETECT } from './consts';

const Footer = ({
  user,
  formulator,
  location,
  formulatorUser,
}) => {
  const faUser = JSON.parse(sessionStorage.getItem('user-info-for-verified'));
  const showFAFooter =
      window.location.pathname.includes('/fa') &&
      !window.location.pathname.includes('faq') &&
      !window.location.pathname.includes('face') &&
      faUser && faUser.access;
  const isNewFooter = true; // Temporary

  return showFAFooter ? null : (
    <Section
      renderAs="footer"
      className={`${styles['wrapper']} ${config.analytic.classes.footer}`}
    >
      <Container>
        <div className={`${styles['section']} ${styles['top-section']}`}>
          <Columns multiline={false}>
            <Columns.Column
              className={styles['connect-column']}
              tablet={{ size: 6 }}
              desktop={{ size: 4 }}
            >
              <div
                className={`${styles['heading']} ${styles['connect-heading']}`}
              >
                Connect & Get the Latest!
              </div>

              {/*<SubscriptionForm className={styles['subscription']} />*/}
              {/*{!isNewFooter && (*/}
              {/*  <Link to="/email-signup" className={styles.link}>*/}
              {/*    Why join our list?*/}
              {/*  </Link>*/}
              {/*)}*/}
              <FollowUs
                  location={location}
                  formulatorUser={formulatorUser}
                className={styles['follow-us']}
                isNewFooter={true}
              />
            </Columns.Column>

            <Columns.Column
              className={styles['menus-column']}
              tablet={{ size: 6 }}
              desktop={{ size: 8 }}
            >
              <Columns>
                <Columns.Column tablet={{ size: 12 }} desktop={{ size: 6 }}>
                  <div className={styles['heading']}>The Good Face Project</div>

                  <ul className={styles['menu']}>
                    <li>
                      <Link
                          to="/about"
                          // onClick={() => {
                          //   if (URLS_DETECT[location.pathname]) {
                          //     segmentsFooterTrack({
                          //       user: formulatorUser,
                          //       name: 'About Button Clicked',
                          //       description:
                          //           'User clicks on About button in any of the following pages: About, Careers, Compare, Consumer FAQ, Consumer Blog, Research + Shop, FAQ, Formulators & Manufacturers, Brands, Retailers, Ingredient Suppliers, Claim Validation, Home page, Login Page',
                          //       track_fields: {
                          //         $current_url: window.location.href,
                          //         current_page: URLS_DETECT[location.pathname],
                          //       },
                          //     });
                          //   }
                          // }}
                      >About</Link>
                    </li>
                    <li>
                      <Link
                          to="/faq"
                          // onClick={() => {
                          //   if (URLS_DETECT[location.pathname]) {
                          //     segmentsFooterTrack({
                          //       user: formulatorUser,
                          //       name: 'FAQ Button Clicked',
                          //       description:
                          //           'User clicks on FAQ button in any of the following pages: About, Careers, Compare, Consumer FAQ, Consumer Blog, Research + Shop, FAQ, Formulators & Manufacturers, Brands, Retailers, Ingredient Suppliers, Claim Validation, Home page, Login Page',
                          //       track_fields: {
                          //         $current_url: window.location.href,
                          //         current_page: URLS_DETECT[location.pathname],
                          //       },
                          //     });
                          //   }
                          // }}
                      >{isNewFooter ? 'FAQ' : 'FAQs'}</Link>
                    </li>
                    {!isNewFooter && (
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                    )}
                    <li>
                      <Link
                        to="/demo"
                        target="_blank"
                        onClick={(e) => {

                          if (URLS_DETECT[location.pathname]) {
                            segmentsFooterTrack({
                              user: formulatorUser,
                              name: 'Contact Button Clicked',
                              description:
                                  'User clicks on Contact button in any of the following pages: About, Careers, Compare, Consumer FAQ, Consumer Blog, Research + Shop, FAQ, Formulators & Manufacturers, Brands, Retailers, Ingredient Suppliers, Claim Validation, Home page, Login Page',
                              track_fields: {
                                $current_url: window.location.href,
                                current_page: URLS_DETECT[location.pathname],
                              },
                            });
                          }
                        }}
                      >
                        {isNewFooter ? 'Contact' : 'Press Inquiries'}
                      </Link>
                    </li>

                  </ul>
                </Columns.Column>

                {!isNewFooter && (
                  <Columns.Column tablet={{ size: 12 }} desktop={{ size: 6 }}>
                    <div className={styles['heading']}>Retailers</div>

                    <ul className={styles['menu']}>
                      <li>
                        <Link to="/retail">API Solutions</Link>
                      </li>
                    </ul>
                  </Columns.Column>
                )}

                {!isNewFooter && (
                  <Columns.Column tablet={{ size: 12 }} desktop={{ size: 6 }}>
                    <div className={styles['heading']}>Brands</div>

                    <ul className={styles['menu']}>
                      <li>
                        <Link to="/brands">Good Face Index Membership</Link>
                      </li>
                      <li>
                        <Link to="/">Innovation Platform</Link>
                      </li>
                      {(!user.isLoggedIn || user.role === 'company_user') && (
                        <li>
                          {user.isLoggedIn ? (
                            <a href="/sa/products#/">Brand Login</a>
                          ) : (
                            <Link to="/accounts/login?type=brand&next=/sa/products#/">
                              Brand Login
                            </Link>
                          )}
                        </li>
                      )}
                      {(user.isLoggedIn || formulator.isLoggedIn) && (
                        <li>
                          <a href="/accounts/logout">Logout</a>
                        </li>
                      )}
                    </ul>
                  </Columns.Column>
                )}
              </Columns>
            </Columns.Column>
          </Columns>
        </div>

        <div className={`${styles['section']} ${styles['bottom-section']}`}>
          <a
            href="https://evonexus.org"
            target="_blank"
            className={styles['logo']}
            rel="noopener noreferrer"
          >
            <EvoNexusLogo alt="Evo Nexus Logo" />
          </a>

          <div className={styles['links-wrap']}>
            <div className={styles['links']}>
              <Link to="/f_privacy_policy">Privacy Policy</Link>
              <Link to="/f_terms_and_conditions">Terms & Conditions</Link>
            </div>

            <div className={styles['copyright']}>
              <span>The Good Face Project, Inc 2024.</span> &nbsp;
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default connect((state) => ({
  user: state.user,
  formulator: state.formulator,
  formulatorUser: state.formulator.user,
  previousLocation: state.formulator.previousLocation,
  featureFlags: state.user.featureFlags,
}))(withRouter(Footer));
