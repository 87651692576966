import {combineReducers} from 'redux';

import compoundIngredientsTrash from '../store/trash/CompoundIngredientsGet/slice'
import consumerProductsTrash from '../store/trash/ConsumerProductsGet/slice'
import formulatorFoldersTrash from '../store/trash/FormulatorFoldersGet/slice'
import formulatorProductsTrash from '../store/trash/FormulatorProductsGet/slice'

import general from './general'
import singleProduct from './singleProduct'
import user from './user'
import formulator from './formulator'
import reports from './reports'
import tnl from './tnl'
import cara from './cara'
import snackbar from './snackbar'
import partnerships from './partnerships'
import rawMaterials from  './rawMaterials'

const reducer = combineReducers({
  singleProduct,
  user,
  formulator,
  general,
  reports,
  tnl,
  cara,
  compoundIngredientsTrash,
  consumerProductsTrash,
  formulatorFoldersTrash,
  formulatorProductsTrash,
  snackbar,
  partnerships,
  rawMaterials,
});


export default reducer;
