import React, {Component} from 'react';
import MediaQuery from 'react-responsive';

const breakpoints = {
  tabletBreakpoint: 1139,
  mobileBreakpoint: 767
};

class Responsive extends Component {

  static matches = {
    desktop: 'desktop',
    tablet: 'tablet',
    tabletDown: 'tablet-down',
    mobileUp: 'mobile-up',
    mobile: 'mobile'
  };

  render() {

    const {
      match,
      children,
      component = false,
      ...rest
    } = this.props;

    const {tabletBreakpoint, mobileBreakpoint} = breakpoints;

    let matchFunction = children;

    if (component) {
      matchFunction = (match) => match ? component : null;
    } else if (typeof children !== "function") {
      matchFunction = (match) => match ? children : null;
    }

    switch (match) {
      case Responsive.matches.desktop :
        return <MediaQuery {...rest} children={matchFunction} minWidth={tabletBreakpoint + 1}/>;
      case Responsive.matches.tablet :
        return <MediaQuery {...rest} children={matchFunction} minWidth={mobileBreakpoint + 1}
                           maxWidth={tabletBreakpoint}/>;
      case Responsive.matches.tabletDown :
        return <MediaQuery {...rest} children={matchFunction} maxWidth={tabletBreakpoint}/>;
      case Responsive.matches.mobileUp :
        return <MediaQuery {...rest} children={matchFunction} minWidth={mobileBreakpoint + 1}/>;
      case Responsive.matches.mobile :
        return <MediaQuery {...rest} children={matchFunction} maxWidth={mobileBreakpoint}/>;
      default:
        return null
    }

  }

}


export default Responsive;

