import React from 'react';

import Loader from '../Loader'

import styles from './index.module.scss';

const Loading = ({ isLoading, error }) => {
    // Handle the loading state
    if (isLoading) {
        return <Loader fw />;
    }
    // Handle the error state
    else if (error) {
        window.location.reload();

        return   <div className={styles['problem']}>

        </div>;
    }
    else {
        return null;
    }
};

export default Loading;
