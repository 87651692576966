import Cookies from 'universal-cookie';
import axiosInstance from '../actions/axiosInstance';

export const validate = (schema) => {
  return values => {
    try {
      schema.validateSync(values, {
        abortEarly: false,
        stripUnknown: true
      });

      return {};
    } catch (result) {
      let errors = {};
      result.inner.forEach(error => {
        if (errors[error.path] === undefined) {
          errors[error.path] = [error.message]
        } else {
          errors[error.path].push(error.message);
        }
      });

      return errors;
    }
  }
};

export const subscribe = async (args) => {

  const data = new FormData();

  for (const [key, value] of Object.entries(args)) {
    data.set(key, value);
  }

  let result = {
    success: false,
    message: ''
  }

  try {

    const resp = await axiosInstance.post('/subscribe', data);

    if (resp.data.errors !== undefined) {

      let errorMsg = resp.data.errors;

      if (errorMsg === 'Member Exists') {
        errorMsg = 'Good news! You are already subscribed for updates.';
      }

      result.message = errorMsg;

    } else {

      const cookies = new Cookies();
      cookies.set('gfp-subscribed', 1, {path: '/', maxAge: 86400 * 365});

      result.message = resp.data.result;
      result.success = true;

    }

  } catch (e) {
    result.message = 'We have technical problem. Please try again later.';
  }

  return result;

};

// Sorting array of objects by custom key
export const sortArrayForKey = (array, key) => array.sort((a, b) => {
  const nameA = a[key] || '';
  const nameB = b[key] || '';

  // Check if both names are numeric
  const isNumericA = !isNaN(nameA);
  const isNumericB = !isNaN(nameB);

  if (isNumericA && isNumericB) {
    // Compare as numbers
    return parseInt(nameA, 10) - parseInt(nameB, 10);
  }

  // Compare as strings
  return nameA.localeCompare(nameB);
});

// Update the select list in dropdown menu {Select from 'react-select'}
export  const updateSelectOption = ({
  data, options, setOptions,
}) => {
  let newOptions = options;

  if (data) {

    if (Array.isArray(data)) {
      const parseData = data.map((option) => ({
        value: option.id, label: option.name,
      }));

      newOptions = ([
        ...options,
        ...parseData,
      ]);
    } else {
      newOptions = ([
        ...options,
        { value: data.id, label: data.name },
      ]);
    }
  }

  const sortedGroups = sortArrayForKey(newOptions, 'label');

  setOptions(sortedGroups);
};
