import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { catchFunction, getConfig } from 'actions/utils';
import axiosInstance from '../../../actions/axiosInstance';

export const initialState = {
  formulatorFolders: undefined,
  error: undefined,
  isLoading: false,
};

export const deleteTrashFormulatorFoldersBulk = createAsyncThunk(
  'delete_formulator_folders_bulk',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/rest_api/trash/formulator/folders/bulk`, getConfig(data))

      return response.data;
    } catch (error) {
      catchFunction({
        error,
        dispatch,
        refreshFunction: deleteTrashFormulatorFoldersBulk(data),
      });

      return rejectWithValue(error.message);
    }
  },
);

export const deleteFormulatorFoldersTrashBulk = createSlice({
  name: 'delete_formulator_folders_bulk',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteTrashFormulatorFoldersBulk.pending, (state) => {
        state.isLoading = true;
        state.formulatorFolders = undefined;
        state.error = undefined;
      })
      .addCase(deleteTrashFormulatorFoldersBulk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.formulatorFolders = action.payload;
      })
      .addCase(deleteTrashFormulatorFoldersBulk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default deleteFormulatorFoldersTrashBulk.reducer;
