import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'delayed-scroll-restoration-polyfill/index.es6';

import React from 'react';
import {render} from "react-dom";
import {Provider} from 'react-redux'
import flagsmith from 'flagsmith'
import {FlagsmithProvider} from 'flagsmith/react'

import * as serviceWorker from './serviceWorker';
import store from './store'
import {
  getAllPermissions,
  getCompanyProfile,
  getUserProfile,
  getUserStatus
} from "./actions";
import App from './App';
import {getApiBaseFeatureFlagsKey} from "./utils/utils";
import { isFormulasPage } from "./utils/isFormulasPage";

const user = JSON.parse(sessionStorage.getItem(
    'user-info-for-verified'
));
const token = user?.access

if (!sessionStorage.getItem('tabId')) {
  sessionStorage.setItem('tabId', Date.now().toString());
}

if (token) {
    store.dispatch(getUserProfile());
    store.dispatch(getAllPermissions(false, isFormulasPage()));
    store.dispatch(getCompanyProfile());
} else {
    store.dispatch(getUserStatus());
    window.sessionStorage.removeItem('Overdue')
}

const removeLoginIfInactive = () => {
  const user = sessionStorage.getItem('user-info-for-verified');

  if (!user) {
    window.localStorage.removeItem('login');
  }
};

window.addEventListener('load', removeLoginIfInactive);
window.addEventListener('beforeunload', removeLoginIfInactive);

render(
  <Provider store={store}>
      <FlagsmithProvider options={{
          environmentID: getApiBaseFeatureFlagsKey(),
          cacheFlags:true
      }} flagsmith={flagsmith}>
            <App flagsmith={flagsmith} />
      </FlagsmithProvider>
  </Provider>
  , document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
