/* eslint-disable react-hooks/exhaustive-deps*/

import {useEffect, useRef, useState} from 'react';
import axiosInstance from '../actions/axiosInstance';

export const useDataApi = (args) => {

  const {
    url,
    params = {},
    data = [],
    method = "get",
    setData = false,
    setDepsParams = false,
    deps = []
  } = args;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [dataState, setDataState] = useState(data);
  const [paramsState, setParams] = useState(params);

  const changedParamsKeys = deps.filter(depKey => params[depKey] !== paramsState[depKey]);

  if (changedParamsKeys.length > 0) {

    let changedParams = {};
    changedParamsKeys.forEach(key => changedParams[key] = params[key]);

    if (setDepsParams && typeof setDepsParams === 'function') {
      setParams(setDepsParams(changedParams, paramsState));
    } else {
      setParams({
        ...paramsState,
        ...changedParams
      });
    }

  }

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoaded(false);
      setIsLoading(true);

      const paramsKey = method === 'get' ? 'params' : 'data';

      try {

        let result = await axiosInstance({
          method,
          url,
          [paramsKey]: paramsState
        });

        result = result.data.success !== undefined ? result.data.data : result.data;

        if (setData && typeof setData === 'function') {
          setDataState(setData(result, dataState, paramsState));
        } else {
          setDataState(result);
        }

        setIsLoaded(true);
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [paramsState]);

  return [{data: dataState, params: paramsState, isLoading, isLoaded, isError}, setParams];
};

export const useDidMountEffect = (func, deps) => {

  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      return func();
    } else {
      didMount.current = true;
    }
  }, deps);

}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
