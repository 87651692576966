export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const ADD_NOTICE = 'ADD_NOTICE';
export const REMOVE_NOTICE = 'REMOVE_NOTICE';
export const OPEN_PRESS_POPUP = 'OPEN_PRESS_POPUP';
export const CLOSE_PRESS_POPUP = 'CLOSE_PRESS_POPUP';
export const OPEN_REQUEST_REVIEW_POPUP = 'OPEN_REQUEST_REVIEW_POPUP';
export const CLOSE_REQUEST_REVIEW_POPUP = 'CLOSE_REQUEST_REVIEW_POPUP';
export const OPEN_SUBSCRIPTION_POPUP = 'OPEN_SUBSCRIPTION_POPUP';
export const CLOSE_SUBSCRIPTION_POPUP = 'CLOSE_SUBSCRIPTION_POPUP';
export const OPEN_ANDROID_NOTIFY_POPUP = 'OPEN_ANDROID_NOTIFY_POPUP';
export const CLOSE_ANDROID_NOTIFY_POPUP = 'CLOSE_ANDROID_NOTIFY_POPUP';
export const SHOW_STANDARD_MESSAGE = 'SHOW_STANDARD_MESSAGE';
export const CLOSE_STANDARD_MESSAGE = 'CLOSE_STANDARD_MESSAGE';
export const OPEN_PROMO_BAR = 'OPEN_PROMO_BAR';
export const CLOSE_PROMO_BAR = 'CLOSE_PROMO_BAR';
export const OPEN_QUESTION_POPUP = 'OPEN_QUESTION_POPUP';
export const CLOSE_QUESTION_POPUP = 'CLOSE_QUESTION_POPUP';

export const addError = (key, message) => ({
  type: ADD_ERROR,
  data: {
    key,
    message
  }
});

export const removeError = (key) => ({
  type: REMOVE_ERROR,
  data: {
    key
  }
});

export const addNotice = (key, message) => ({
  type: ADD_NOTICE,
  data: {
    key,
    message
  }
});

export const removeNotice = (key) => ({
  type: REMOVE_NOTICE,
  data: {
    key
  }
});

export const openPressPopup = () => ({
  type: OPEN_PRESS_POPUP
});

export const closePressPopup = () => ({
  type: CLOSE_PRESS_POPUP
});

export const openRequestReviewPopup = () => ({
  type: OPEN_REQUEST_REVIEW_POPUP
});

export const closeRequestReviewPopup = () => ({
  type: CLOSE_REQUEST_REVIEW_POPUP
});

export const openSubscriptionPopup = () => ({
  type: OPEN_SUBSCRIPTION_POPUP
});

export const closeSubscriptionPopup = () => ({
  type: CLOSE_SUBSCRIPTION_POPUP
});

export const openAndroidNotifyPopup = () => ({
  type: OPEN_ANDROID_NOTIFY_POPUP
});

export const closeAndroidNotifyPopup = () => ({
  type: CLOSE_ANDROID_NOTIFY_POPUP
});

export const showStandardMessage = (message) => ({
  type: SHOW_STANDARD_MESSAGE,
  message: message
});

export const closeStandardMessage = () => ({
  type: CLOSE_STANDARD_MESSAGE
});

export const openPromoBar = () => ({
  type: OPEN_PROMO_BAR
});

export const closePromoBar = () => ({
  type: CLOSE_PROMO_BAR
});

export const openQuestionPopup = () => ({
  type: OPEN_QUESTION_POPUP
});

export const closeQuestionPopup = () => ({
  type: CLOSE_QUESTION_POPUP
});
