import React from "react";
import classNames from 'classnames'
import config from "config";
import {isAndroid} from "utils/utils";
import {openAndroidNotifyPopup} from "actions";
import store from "store";

import Button from "../Button_v2";
import {ReactComponent as AppleLogo} from "../../assets/img/Icons/Misc/AppleLogo.svg";

import styles from './index.module.scss'

const DownloadAppButton = ({link, className, children, icon = true, onClick = () => null, checkDisable, ...rest}) => {

  const handleOnClick = (e) => {

    onClick();

    if (!checkDisable && isAndroid()) {
      e.preventDefault();
      store.dispatch(openAndroidNotifyPopup())
    }

  };

  return (
    <Button
      href={link ? link : config.iosAppLink}
      external
      target="_blank"
      className={classNames(className, styles['button'])}
      onClick={handleOnClick}
      {...rest}
    >
      {icon && <AppleLogo/>}
      {children}
    </Button>
  )

}

export default DownloadAppButton;
