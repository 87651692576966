import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { catchFunction, getConfig } from 'actions/utils';
import axiosInstance from '../../../actions/axiosInstance';

import { updateTrashConsumerProductsBulk } from '../ConsumerProductsUpdateBulk/slice';
import { updateTrashConsumerProducts } from '../ConsumerProductsUpdate/slice';
import { deleteTrashConsumerProductsBulk } from '../ConsumerProductsDeleteBulk/slice';
import { deleteTrashConsumerProducts } from '../ConsumerProductsDelete/slice';

export const initialState = {
  consumerProducts: undefined,
  error: undefined,
  isLoading: false,
};

export const getTrashConsumerProducts = createAsyncThunk(
  'get_consumer_products',
  async (sort, { dispatch, rejectWithValue }) => {
    const sortQuery = sort ? `?sort=${sort}` : '';
    try {
      const response = await axiosInstance.get(
        `/rest_api/trash/products${sortQuery}`,
        getConfig(),
      );

      return response.data.data;
    } catch (error) {
      catchFunction({
        error,
        dispatch,
        refreshFunction: getTrashConsumerProducts(),
      });

      return rejectWithValue(error.message);
    }
  },
);

export const consumerProductsTrash = createSlice({
  name: 'get_consumer_products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrashConsumerProducts.pending, (state) => {
        state.isLoading = true;
        state.consumerProducts = undefined;
        state.error = undefined;
      })
      .addCase(getTrashConsumerProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.consumerProducts = action.payload;
      })
      .addCase(getTrashConsumerProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateTrashConsumerProductsBulk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.consumerProducts = action.payload
      })
      .addCase(updateTrashConsumerProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.consumerProducts = state.consumerProducts.map((trash) => {
          if (trash.id === action.payload.id) {
            return action.payload;
          }

          return trash;
        });
      })
      .addCase(deleteTrashConsumerProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.consumerProducts = state.consumerProducts?.filter(
          (trash) => {
            return trash.id !== action.payload.id;
          },
        );
      })
      .addCase(deleteTrashConsumerProductsBulk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.consumerProducts = state.consumerProducts?.filter(
          (trash) => {
            return trash.id !== action.payload.id;
          },
        );
      });
  },
});

export default consumerProductsTrash.reducer;
