import React from 'react';
import classNames from 'classnames';

import Modal from '../../Modal';
import Button from '../../Button_v2';

import styles from './index.module.scss';

const OverdueInvoicePopup = (props) => {

  const { isOpen, closePopup, endDate } = props;

  function calculateDaysRemaining(endDate) {
    const endDateTime = new Date(endDate).getTime();
    const currentDateTime = Date.now();

    const timeDifference = endDateTime - currentDateTime;

    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return `${daysRemaining} days`;
  }

  const daysRemaining = calculateDaysRemaining(endDate);

  return (
    <Modal isOpen={isOpen}>
      <div
        className={classNames(
          styles['modal'],
        )}
      >
        <Button className={styles['modal__close']} onClick={closePopup} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <i className={styles['modal__icon']} />
          <div className={styles['modal__title']}>Overdue Invoice</div>
        </div>
        <div className={styles['modal__msg']}>
          Uh oh! Your account has an overdue invoice. <br />
          <b>Please arrange for payment as soon as possible,
            otherwise you will lose access to Good Face in:</b>
        </div>
        <div className={styles['modal__time']}>{daysRemaining}</div>
        <div className={styles['modal__btn-container']}>
          <Button
            className={styles['modal__btn']}
            onClick={closePopup}
          >
            Ok, I got it
          </Button>
        </div>
      </div>
    </Modal>
  );
};


export default OverdueInvoicePopup;
