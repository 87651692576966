/* eslint-disable react-hooks/exhaustive-deps*/

import React, { useState } from 'react';
import Modal from "../../Modal";
import Button from "../../Button_v2";

import styles from './index.module.scss';

const UpdateBrowserPopup = (props) => {
  const {updateBrowser} = props
  const [isOpen, setIsOpen] = useState(updateBrowser)

  const closePopup = (condition) => {
    setIsOpen(condition)
  };

  return (
      <Modal
          isOpen={isOpen}
          className={styles['modal']}
      >
        <div>
          <Button className={styles['modal__close']} onClick={() => closePopup(false)} />
          <div className={styles['modal__title']}>
            Uh oh, it looks like you’re using an outdated browser. Please use an updated browser to access your GF Formulator account.
          </div>
          <div className={styles['modal__inf-block']}>
            <i className={styles['modal__inf-icon']}/>
            <div className={styles['modal__text']}>
              We recommend Chrome for maximum security.
            </div>
          </div>
          <Button className={styles['modal__btn']} onClick={() => closePopup(false)}>
              Ok, I got it
          </Button>
        </div>
      </Modal>
  );
}

export default UpdateBrowserPopup;
