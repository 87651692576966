import {
    CARA_CREATE_TABLE_REQUEST_SUCCESS,
    CARA_RM_LIST_FILTER_REQUEST_SUCCESS,
    CARA_RM_LIST_REQUEST_SUCCESS,
    CARA_SENTIMENT_WORD_CLOUD_REQUEST_SUCCESS,
    CARA_TNL_LIST_FILTER_REQUEST_SUCCESS,
    CARA_TNL_LIST_REQUEST_SUCCESS,
    CLEAR_RM_LIST,
    CLEAR_RM_LIST_FILTER,
    CLEAR_TNL_LIST,
    CLEAR_TNL_LIST_FILTER,
    CLEAR_CLEAR_PAGE,
} from 'actions/types';

export default function reducer(state = {isLoggedIn: false, role: false}, action) {
    switch (action.type) {

        case CARA_RM_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                error: '',
                caraRMList: action.data
            }
        case CARA_TNL_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                error: '',
                caraTNLList: action.data
            }
        case CARA_RM_LIST_FILTER_REQUEST_SUCCESS:
            return {
                ...state,
                error: '',
                caraRMListFilter: action.data
            }
        case CARA_TNL_LIST_FILTER_REQUEST_SUCCESS:
            return {
                ...state,
                error: '',
                caraTNLListFilter: action.data
            }
        case CLEAR_RM_LIST:
            return {
                ...state,
                caraRMList: []
            }
        case CLEAR_TNL_LIST:
            return {
                ...state,
                caraTNLList: []
            }
        case CLEAR_TNL_LIST_FILTER:
            return {
                ...state,
                caraTNLListFilter: []
            }
        case CLEAR_RM_LIST_FILTER:
            return {
                ...state,
                caraRMListFilter: []
            }
        case CARA_CREATE_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                caraTableList: action.data
            }
        case CARA_SENTIMENT_WORD_CLOUD_REQUEST_SUCCESS:
            return {
                ...state,
                caraSentimentAnalysisWordCloud: action.data
            }
        case CLEAR_CLEAR_PAGE:
            return {
                ...state,
                caraRMList: [],
                caraRMListFilter: [],
                caraTNLList: [],
                caraTNLListFilter: []
            }

        default:
            return state;
    }
}


