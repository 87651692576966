import React, { useState } from 'react';
import classNames from 'classnames'

import styles from "./index.module.scss";

const UserGuidAccordion = (props) => {
  const { headerContent, content } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classNames(styles['accordion'])}>
        <div className={styles['accordion__header']} onClick={() => setIsOpen(!isOpen)} onKeyDown={() => setIsOpen(!isOpen)}>
            <div>
                {headerContent}
            </div>
            <div className={styles['accordion__counter']}>
                {isOpen ? "Less" : "More"}
                <i className={classNames(styles['accordion__counter-caret'], isOpen && styles['-open'])} />
            </div>
        </div>
        <div className={classNames(styles['accordion__content'], isOpen && styles['show'])}>
            {content}
        </div>
    </div>
  )
}

export default UserGuidAccordion;
