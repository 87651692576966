import React from 'react';
import classNames from 'classnames';

import Icon from '../Icon';

import './index.scss';
import { URLS_DETECT } from '../Footer/consts';
import { segmentsFooterTrack } from '../../utils/trackEvents';

const FollowUs = ({
  className,
  isNewFooter,
  location,
  formulatorUser,
}) => {
  const wrapClasses = classNames('follow-us', className);

  const tackClick = (mediaButton) => {
    if (URLS_DETECT[location.pathname]) {
      // segmentsFooterTrack({
      //   user: formulatorUser,
      //   name: 'Social Media Icon Clicked',
      //   description:
      //     'User clicks on social media icon: LinkedIn, Facebook, Instagram or Twitter, in any of the following pages: About, Careers, Compare, Consumer FAQ, Consumer Blog, Research + Shop, FAQ, Formulators & Manufacturers, Brands, Retailers, Ingredient Suppliers, Claim Validation, Home page, Login Page',
      //   track_fields: {
      //     social_media_icon: mediaButton,
      //     $current_url: window.location.href,
      //     current_page: URLS_DETECT[location.pathname],
      //   },
      // });
    }
  };

  return isNewFooter ? (
    <div className={wrapClasses}>
      <a
        href="https://www.linkedin.com/company/goodfaceproject/"
        target="_blank"
        rel="noopener noreferrer"
        className="follow-us__link"
        onClick={() => {
          tackClick('Linkedin');
        }}
      >
        <Icon name="SocialLinkedIn" />
      </a>
      <a
        href="https://www.facebook.com/The-Good-Face-Project-1864109123901572/"
        target="_blank"
        rel="noopener noreferrer"
        className="follow-us__link"
        onClick={() => {
          tackClick('Facebook');
        }}
      >
        <Icon name="SocialFacebook" />
      </a>
      <a
        href="https://www.instagram.com/goodfaceproject/"
        target="_blank"
        rel="noopener noreferrer"
        className="follow-us__link"
        onClick={() => {
          tackClick("Instagram");
        }}
      >
        <Icon name="SocialInstagram" />
      </a>
      {/*<a*/}
      {/*  href="https://twitter.com/goodfaceproject"*/}
      {/*  target="_blank"*/}
      {/*  rel="noopener noreferrer"*/}
      {/*  className="follow-us__link"*/}
      {/*  onClick={() => {*/}
      {/*    tackClick();*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Icon name="SocialTwitter" />*/}
      {/*</a>*/}
    </div>
  ) : (
    <div className={wrapClasses}>
      <a
        href="https://www.instagram.com/goodfaceproject/"
        target="_blank"
        rel="noopener noreferrer"
        className="follow-us__link"
      >
        <Icon name="SocialInstagram" />
      </a>
      <a
        href="https://www.facebook.com/The-Good-Face-Project-1864109123901572/"
        target="_blank"
        rel="noopener noreferrer"
        className="follow-us__link"
      >
        <Icon name="SocialFacebook" />
      </a>
      <a
        href="https://twitter.com/goodfaceproject"
        target="_blank"
        rel="noopener noreferrer"
        className="follow-us__link"
      >
        <Icon name="SocialTwitter" />
      </a>
      <a
        href="https://www.pinterest.com/thegoodfaceproject/"
        target="_blank"
        rel="noopener noreferrer"
        className="follow-us__link"
      >
        <Icon name="SocialPinterest" />
      </a>
    </div>
  );
};

export default FollowUs;
