import axiosInstance from './axiosInstance';
import {
  CLEAR_PARTNERSHIPS_RESTRICTIONS,
  GET_PARTNERSHIPS,
  GET_PARTNERSHIPS_SHARED_ITEMS,
  GET_RESTRICTION_ANALYZE, GET_SHARED_PRODUCT,
  REMOVE_PARNTERSHIP,
  REQUEST_REVIEW_INGREDIENT,
} from './types';
import { catchFunction, getConfig } from "./utils";

export function getPartnershipsList(params) {
  const {
    type = '',
    search = '',
    partner_ids = [],
    category = [],
    subcategory = [],
    product_type = [],
    shared_date = '',
    toxin_group_ids = [],
    is_retailer,
    invitation_status = '',
  } = params;
  const searchQuery = search ? `&search=${encodeURIComponent(search)}` : '';
  const partnerIds = partner_ids.length
      ? `&company_ids=${partner_ids.join('&company_ids=')}`
      : '';
  const categoryQuery = category.length
      ? `&category_ids=${category.join('&category_ids=')}`
      : '';
  const subcategoryQuery = subcategory.length
      ? `&subcategory_ids=${subcategory.join('&subcategory_ids=')}`
      : '';
  const productType = product_type?.length
      ? `&product_type_ids=${product_type.join('&product_type_ids=')}`
      : '';
  const partnership_type = type ? `&partnership_type=${type}` : '';
  const sharedDateQuery = shared_date ? `&shared_date=${shared_date}` : '';
  const complianceQuery = toxin_group_ids.length
      ? `&toxin_group_ids=${toxin_group_ids.join('&toxin_group_ids=')}`
      : '';
  const isRetailer = is_retailer ? `&is_retailer=true` : '';
  const isAccepted = invitation_status === 'accepted' ? '&invitation_status=accepted' : ''
  const filters = `${partnerIds}${categoryQuery}${subcategoryQuery}${productType}${sharedDateQuery}${complianceQuery}${isRetailer}${isAccepted}`;

  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/partnerships?offset=0${partnership_type}${searchQuery}${filters}`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: GET_PARTNERSHIPS,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getPartnershipsList(params),
          });
        });
  };
}

export function deletePartnerships(partnershipId) {
  return (dispatch) => {
    return axiosInstance
        .delete(
            `/rest_api/partnerships/${partnershipId}`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })

        .then((response) => {
          dispatch({
            type: REMOVE_PARNTERSHIP,
            data: response,
          });
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: deletePartnerships(partnershipId),
          });
        });
  };
}

export function acceptInvitationPartnerships(partnershipId) {
  return (dispatch) => {
    return axiosInstance
        .post(
            `/rest_api/partnerships/accept-invitation`,
            { partnership_id: partnershipId },
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })

        .then((response) => {
          dispatch({
            type: REQUEST_REVIEW_INGREDIENT,
            data: response,
          });
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: acceptInvitationPartnerships(partnershipId),
          });
        });
  };
}

export function declineInvitationPartnerships(partnershipId) {
  return (dispatch) => {
    return axiosInstance
        .post(
            `/rest_api/partnerships/decline-invitation`,
            { partnership_id: partnershipId },
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: REQUEST_REVIEW_INGREDIENT,
            data: response,
          });
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: declineInvitationPartnerships(partnershipId),
          });
        });
  };
}

export function sendInvitationPartnerships(partnershipId) {
  return (dispatch) => {
    return axiosInstance
        .post(
            `/rest_api/partnerships/send-invitation`,
            { partnership_id: partnershipId },
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: REQUEST_REVIEW_INGREDIENT,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: sendInvitationPartnerships(partnershipId),
          });
        });
  };
}

export function getPartnershipsFormulas(data) {
  const searchQuery = data.search ? `&search=${encodeURIComponent(data.search)}` : '';
  const categoryQuery = data.category?.length
      ? `&category_ids=${data.category.join('&category_ids=')}`
      : '';
  const subcategoryQuery = data.subcategory?.length
      ? `&subcategory_ids=${data.subcategory.join('&subcategory_ids=')}`
      : '';
  const productType = data.product_type?.length
      ? `&product_type_ids=${data.product_type.join('&product_type_ids=')}`
      : '';
  const sharedDateQuery = data.shared_date ? `&shared_date=${data.shared_date}` : '';
  const complianceQuery = data.toxin_group_ids?.length
      ? `&toxin_group_ids=${data.toxin_group_ids.join('&toxin_group_ids=')}`
      : '';

  const filters = `${searchQuery}${categoryQuery}${subcategoryQuery}${productType}${sharedDateQuery}${complianceQuery}`;

  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/partnerships/${data.partnershipId}/formulas?offset=${data.offset}${filters}`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: GET_PARTNERSHIPS_SHARED_ITEMS,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getPartnershipsFormulas(data),
          });
        });
  };
}

export function getPartnershipsFormula(partnerId, itemId) {
  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/partnerships/${partnerId}/formulas/${itemId}`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: GET_SHARED_PRODUCT,
            data: response.id ? response : {},
          });
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getPartnershipsFormula(partnerId, itemId),
          });
        });
  };
}


export function getPartnershipsFromulaAnalyze(partnershipId, productId) {
  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/partnerships/${partnershipId}/formulas/${productId}/analyze`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: GET_RESTRICTION_ANALYZE,
            data: response,
          });
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getPartnershipsFromulaAnalyze(
                partnershipId,
                productId,
            ),
          });
        });
  };
}

export function getPartnershipsFormulaAlergens(partnershipId, productId) {
  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/partnerships/${partnershipId}/formulas/${productId}/compound-allergens`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: REQUEST_REVIEW_INGREDIENT,
            data: response,
          });

          return response
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getPartnershipsFormulaAlergens(
                partnershipId,
                productId,
            ),
          });
        });
  };
}

export function getPartnershipsFormulaDocuments(partnershipId, productId) {
  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/partnerships/${partnershipId}/formulas/${productId}/files`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: REQUEST_REVIEW_INGREDIENT,
            data: response,
          });

          return response
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getPartnershipsFormulaDocuments(
                partnershipId,
                productId,
            ),
          });
        });
  };
}

export function createPartnerships(data) {
  return (dispatch) => {
    return axiosInstance
        .post(
            `/rest_api/partnerships`,
            { partner_company_id: data },
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: REQUEST_REVIEW_INGREDIENT,
            data: response,
          });

          return REQUEST_REVIEW_INGREDIENT
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: createPartnerships(data),
          });
        });
  };
}

export function getPartnershipsFormulaIngredients(partnershipId, productId) {
  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/partnerships/${partnershipId}/formulas/${productId}/params`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: REQUEST_REVIEW_INGREDIENT,
            data: response,
          });

          return response
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getPartnershipsFormulaIngredients(
                partnershipId,
                productId,
            ),
          });
        });
  };
}

export function getPartnershipsFormulaCompounds(partnershipId, productId) {
  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/partnerships/${partnershipId}/formulas/${productId}/compound-params`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: REQUEST_REVIEW_INGREDIENT,
            data: response,
          });

          return response
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getPartnershipsFormulaCompounds(
                partnershipId,
                productId,
            ),
          });
        });
  };
}

export function getPartnershipsFormulaCompoundChild(partnershipId, productId, ingredientId) {
  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/partnerships/${partnershipId}/formulas/${productId}/compound-params/${ingredientId}`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: REQUEST_REVIEW_INGREDIENT,
            data: response,
          });

          return response
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getPartnershipsFormulaCompoundChild(
                partnershipId,
                productId,
                ingredientId,
            ),
          });
        });
  };
}

export function getPartnershipsFormulaTotalConcentrarion(partnershipId, productId, ingredientId) {
  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/partnerships/${partnershipId}/formulas/${productId}/total-concentration`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: REQUEST_REVIEW_INGREDIENT,
            data: response,
          });

          return response
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getPartnershipsFormulaTotalConcentrarion(
                partnershipId,
                productId,
                ingredientId,
            ),
          });
        });
  };
}

export function getPartnershipsFormulaCompoundIncis(partnershipId, productId) {
  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/partnerships/${partnershipId}/formulas/${productId}/compound-incies`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: REQUEST_REVIEW_INGREDIENT,
            data: response,
          });

          return response
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getPartnershipsFormulaCompoundIncis(
                partnershipId,
                productId,
            ),
          });
        });
  };
}

export function requestCreatePartnershipsFormulaDocumentArchive(partnershipId, productId) {
  return (dispatch) => {
    return axiosInstance
        .post(
            `/rest_api/partnerships/${partnershipId}/formulas/${productId}/archive`,
            null,
            getConfig()
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: requestCreatePartnershipsFormulaDocumentArchive(partnershipId, productId),
          });

          return Promise.reject(error);
        })
  }
}
export function clearPartnershipsRestrictions() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PARTNERSHIPS_RESTRICTIONS,
    });
  };
}
