import {
    GET_PDF_REPORTS
} from '../actions/types';

export default function reducer(state = {isLoggedIn: false, role: false}, action) {
  switch (action.type) {
    case GET_PDF_REPORTS:
      return {
          ...state,
        reportData: action.data,
      };
      default:
        return state;
  }
}

