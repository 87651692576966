import React, {useState} from 'react';
import {connect} from "react-redux";
import {closePressPopup} from "actions";
import {useFormik} from "formik";
import {object as YupObject, string as YupString} from "yup";
import {validate} from "utils/form";

import Modal from "../../Modal";
import Loader from "../../Loader";
import Button from "../../Button_v2";
import Input from "../../Input_v2";
import Heading from "../../Heading_v2";

import styles from './index.module.scss';
import axiosInstance from '../../../actions/axiosInstance';

const ValidationSchema = YupObject({
  name: YupString()
    .required('required'),
  email: YupString()
    .required('required')
    .email('invalid email'),
  content: YupString()
    .required('required')
});

const PressPopup = (props) => {

  const {isOpen, closePopup} = props,
    [isSending, setIsSending] = useState(false),
    [isSent, setIsSent] = useState(false);

  const form = useFormik({
    initialValues: {
      name: '',
      email: '',
      content: '',
    },
    onSubmit: async values => {

      setIsSending(true);

      try {
        await axiosInstance.post('/publicapi/user/send_inquiry/', values)
        setIsSent(true);
      } catch (e) {
        alert('Sending error');
      } finally {
        setIsSending(false);
      }

    },
    validate: validate(ValidationSchema)
  });

  const onRequestClose = () => {

    closePopup();

    if (isSent) {
      form.resetForm();
      setIsSent(false);
    }

  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div className={styles['modal']}>

        {isSending && <Loader cover transparent/>}

        {!isSent ?
          <>
            <Heading as="h2" className={styles['heading']}>Press Inquiries</Heading>

            <form onSubmit={form.handleSubmit}>
              <Input
                name="name"
                value={form.values.name}
                onChange={form.handleChange}
                placeholder="Name"
              />
              <Input
                name="email"
                value={form.values.email}
                onChange={form.handleChange}
                type="email"
                placeholder="Email"
              />
              <Input
                name="content"
                type="textarea"
                value={form.values.content}
                onChange={form.handleChange}
                placeholder="What is the topic of your inquiry?"
              />
              <Button disabled={!form.dirty || !form.isValid}>Send Message</Button>
            </form>
          </> :
          <div className={styles['success-msg']}>
            <Heading as="h2">
              Thank you for contacting the Good Face Project!
            </Heading>
            <p>We will be in touch shortly.</p>
          </div>
        }

      </div>

    </Modal>
  );
}


export default connect(
  state => ({
    isOpen: state.general.pressPopup
  }),
  dispatch => ({
    closePopup: () => dispatch(closePressPopup())
  })
)(PressPopup);
