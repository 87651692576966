import {GET_PRODUCT} from '../actions';


function parse_benefits(benefits) {

  let newBenefits = {};

  benefits.forEach(benefit => {

    let result = null,
      uid = null;

    switch (benefit.slug) {
      case 'wrinkles':
      case 'sagging':
        uid = 'wrinkles_sagging';
        result = {
          'icon': 'BenefitWrinkles',
          'name': 'Reduces the appearance of wrinkles'
        };
        break;
      case 'dryness':
        uid = 'dryness';
        result = {
          'icon': 'BenefitMoisturizes',
          'name': 'Moisturizes'
        };
        break;
      case 'collagen_loss':
        uid = 'collagen_loss';
        result = {
          'icon': 'BenefitBoostCollagen',
          'name': 'Boosts collagen'
        };
        break;
      case 'irritation':
        uid = 'irritation';
        result = {
          'icon': 'BenefitSootheSensitive',
          'name': 'Soothes sensitive skin'
        };
        break;
      case 'dark_spots':
        uid = 'dark_spots';
        result = {
          'icon': 'BenefitDarkSpots',
          'name': 'Diminishes dark spots'
        };
        break;
      case 'acne':
      case 'blackheads':
        uid = 'acne_blackheads';
        result = {
          'icon': 'BenefitPores',
          'name': 'Shrinks pores & keeps acne at bay'
        };
        break;
      case 'dullness':
      case 'loss_of_undertone':
        uid = 'dullness_loss_of_undertone';
        result = {
          'icon': 'BenefitBrightenTones',
          'name': 'Brightens & tones the skin'
        };
        break;
      case 'balances_ph':
        uid = 'balances_ph';
        result = {
          'icon': 'BenefitPHBalance',
          'name': 'Balances skin\'s pH'
        };
        break;

      case 'sunscreen':
        uid = 'sunscreen';
        result = {
          'icon': 'BenefitUVFilter',
          'name': 'Filters UV rays'
        };
        break;

      case 'wound_healing':
        uid = 'wound_healing';
        result = {
          'icon': 'BenefitHealing',
          'name': 'Enables skin\'s self-repair'
        };
        break;

      default:
        uid = Math.random();
        result = {
          'icon': '',
          'name': benefit.name
        };
    }

    // skip if not found
    if (result.icon === "") {
      return;
    }

    if (newBenefits[uid] === undefined) {
      newBenefits[uid] = {
        ...result,
        count: 1
      }
    } else {
      newBenefits[uid].count++;
    }

  });

  return Object.keys(newBenefits).map(e => newBenefits[e]);

}


export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_PRODUCT:

      if (!Array.isArray(action.data.safe_products)) {
        action.data.safe_products = [];
      }

      if( typeof action.data.safest_match !== "object" ) {
        action.data.safest_match = {};
      }

      action.data.product.benefits = parse_benefits(action.data.product.benefits);
      action.data.safe_products.forEach(product => {
        product.benefits = parse_benefits(product.benefits);
      });

      return {
        ...action.data
      };
    default:
      return state;
  }
}

