import dayjs from "dayjs";

export const segmentsTrack = (data) => {
    const userName = `${data.user?.profile_data?.name} ${data.user?.profile_data?.lastname}`;

    window.analytics && window.analytics.track(data.name, {
        ...data.track_fields,
        ...(data.rm_id && { rm_id: data.rm_id}),
        ...(data.rm_name && { rm_name: data.rm_name}),
        ...(data.folder_id && { folder_id: data.folder_id}),
        ...(data.time_spent && { time_spent: data.time_spent}),
        ...(data.formula_id && { formula_id: data.formula_id}),
        ...(data.documents_count && { documents_count: data.documents_count}),
        ...(data.partner_name && { partner_name: data.partner_name}),
        ...(data.partner_id && { partner_id: data.partner_id}),
        ...(data.bulk_action && { bulk_action: data.bulk_action}),
        ...(data.bulk_actions && { bulk_action: data.bulk_actions}),
        ...(data.formula_selection_count && { formula_selection_count: data.formula_selection_count}),
        ...(data.formulas_selection_count && { formulas_selection_count: data.formulas_selection_count}),
        ...(data.formulator_page && { formulator_page: data.formulator_page}),
        ...(data.folder_expansion && { folder_expansion: data.folder_expansion}),
        ...(data.folder_collapse && { folder_collapse: data.folder_collapse}),
        ...(data.old_folder_id && { old_folder_id: data.old_folder_id}),
        ...(data.filter_category && { filter_category: data.filter_category}),
        ...(data.filter_subcategory && { filter_subcategory: data.filter_subcategory}),
        ...(data.share_category && { share_category: data.share_category}),
        ...(data.partner_company_name && { partner_company_name: data.partner_company_name}),
        ...(data.partner_company_id && { partner_company_id: data.partner_company_id}),
        ...(data.partner_name && { partner_name: data.partner_name}),
        ...(data.partner_id && { partner_id: data.partner_id}),
        ...(data.inci_count && { inci_count: data.inci_count}),
        ...(data.manufacturer_id && { manufacturer_id: data.manufacturer_id}),
        ...(data.manufacturer_name && { manufacturer_name: data.manufacturer_name}),
        ...(data.supplier_id && { supplier_id: data.supplier_id}),
        ...(data.supplier_name && { supplier_name: data.supplier_name}),
        ...(data.has_rm_synonyms && { has_rm_synonyms: data.has_rm_synonyms}),
        ...(data.has_manufacturer && { has_manufacturer: data.has_manufacturer}),
        ...(data.has_supplier && { has_supplier: data.has_supplier}),
        ...(data.has_trade_name && { has_trade_name: data.has_trade_name}),
        ...(data.has_price && { has_price: data.has_price}),
        ...(data.has_functions && { has_functions: data.has_functions}),
        ...(data.has_applications && { has_applications: data.has_applications}),
        ...(data.certifications_count && { certifications_count: data.certifications_count}),
        ...(data.has_rm_reference && { has_rm_reference: data.has_rm_reference}),
        ...(data.has_description && { has_description: data.has_description}),
        ...(data.has_tags && { has_tags: data.has_tags}),

        $distinct_id: data.user?.id,
        user_id: data.user?.id,
        user_name: userName,
        company_id: data.user?.company?.id,
        company_name: data.user?.company?.name,

        event_description: data.description,

        $name: userName,
        $time: dayjs().toString(),
        environment: 'client-side',
        client_datetime: dayjs().toString(),
    });
};

export const segmentsPage = (data) => {
    const userName = `${data.user?.profile_data?.name} ${data.user?.profile_data?.lastname}`;

    window.analytics && window.analytics.page(data.name, {
        ...(data.rm_id && { rm_id: data.rm_id}),
        ...(data.formula_id && { formula_id: data.formula_id}),
        ...(data.folder_id && { folder_id: data.folder_id}),
        ...(data.notes_count && { notes_count: data.notes_count}),

        $distinct_id: data.user.id,
        user_id: data.user?.id,
        user_name: userName,
        company_id: data.company?.id,
        company_name: data.company?.name,

        event_description: data.description,

        $name: userName,
        $time: dayjs().toString(),
        environment: 'client-side',
        client_datetime: dayjs().toString(),
    });
};

export const segmentsTrackWithCallback = (data) => {
    const userName = `${data.user?.profile_data?.name} ${data.user?.profile_data?.lastname}`;

    window.analytics && window.analytics.track(data.name, {
        ...data.track_fields,
        $distinct_id: data.user?.id,
        user_id: data.user?.id,
        user_name: userName,
        company_id: data.user?.company?.id,
        company_name: data.user?.company?.name,
        email: data.user?.email,
        event_description: data.description,

        $name: userName,
        $time: dayjs().toString(),
        environment: 'client-side',
        client_datetime: dayjs().toString(),
    });
};

export const segmentsWebsitePage = (data) => {
    const userName = `${data.user?.profile_data?.name} ${data.user?.profile_data?.lastname}`;

    // window.analytics && window.analytics.page('Website Page Viewed', {
    //     ...data.track_fields,
    //     event_description: 'User views one of the following website pages: About, Careers, Compare, Consumer FAQ, Consumer Blog, Research + Shop, FAQ, Formulators & Manufacturers, Brands, Retailers, Ingredient Suppliers, Claim Validation, Home page, Login Page, Formulator',
    //     $distinct_id: data.user?.id,
    //     user_id: data.user?.id,
    //     $name: userName,
    //     email: data.user?.email,
    //     $time: Date.now(),
    //     company_id: data.user?.company?.id,
    //     company_name: data.user?.company?.name,
    // });

    window.analytics && data.name && window.analytics.page(data.name, {
        ...data.track_fields,
        $distinct_id: data.user?.id,
        user_id: data.user?.id,
        $name: userName,
        email: data.user?.email,
        $time: Date.now(),
        company_id: data.user?.company?.id,
        company_name: data.user?.company?.name,
    });
};

export const segmentsFooterTrack = (data) => {
    const userName = `${data.user?.profile_data?.name} ${data.user?.profile_data?.lastname}`;

    window.analytics && window.analytics.track(data.name, {
        ...data.track_fields,
        ...(data.description && { event_description: data.description}),
        user_name: userName,
        $distinct_id: data.user?.id,
        user_id: data.user?.id,
        $name: userName,
        email: data.user?.email,
        $time: Date.now(),
        company_id: data.user?.company?.id,
        company_name: data.user?.company?.name,
    })

}
export const segmentsFormTrack = (data) => {
    window.analytics && window.analytics.track(data.name, {
        ...data.track_fields,
        event_description: data.description,
        $distinct_id: data.user?.id,
        user_id: data.user?.id,
        email: data.user?.email,
        $time: Date.now(),
        company_id: data.user?.company?.id,
    })

}

export const segmentsTrackDefault = (data) => {
    window.analytics && window.analytics.track(data.name, {
        ...data.track_fields,
    })

}
