import React from "react";
import classNames from "classnames";
import dayjs from "dayjs";

import styles from "./index.module.scss";

const notificationTypeMap = {
  status: 'STATUS',
  action: 'Action',
  new_feature: 'New Feature',
  terms_conditions: 'Terms and Conditions',
};

const NotificationsContent = (notifications) => {
  const formatDate = (day) => {
    const currentDay = dayjs(day);
    var utc = new Date();
    var offset = utc.getTimezoneOffset();
    var local = new Date(utc.getTime() + offset * 60000);
    const today = dayjs(local);

    const diffHrs = today.diff(currentDay, 'hour');
    const diffMins = today.diff(currentDay, 'minute');
    const diffSeconds = today.diff(currentDay, 'second');

    if (!diffMins) {
      return `${diffSeconds} seconds ago`;
    } else if (!diffHrs) {
      return `${diffMins} minutes ago`;
    } else if (diffHrs === 1) {
      return `${diffHrs} hour ago`;
    } else if (diffHrs < 24) {
      return `${diffHrs} hours ago`;
    } else {
      return currentDay.format('DD-MMM-YY');
    }
  };

  const getDayDivider = (notificationDay, index, indexStart) => {
    const currentNotificationDay = dayjs(notificationDay);
    const previousDate = index ? dayjs(notifications[index - 1]?.notification?.created_at) : currentNotificationDay;
    const isSameDate = dayjs(previousDate).isSame(currentNotificationDay, 'day');
    const isToday = currentNotificationDay.isSame(dayjs() , 'day');
    const isYesterdayDay = currentNotificationDay.isSame(dayjs().add(-1, 'day'), 'day');
    const isTwoDaysAgo = currentNotificationDay.isSame(dayjs().add(-2, 'day'), 'day');

    if (!isSameDate && isYesterdayDay) {
      return <div className={styles['user-notifications__divider']}>Yesterday</div>
    } else if (!isSameDate) {
      return <div className={styles['user-notifications__divider']}>{currentNotificationDay.format('DD-MMM-YY')}</div>
    } else if (isToday && index === indexStart) {
      return <div className={styles['user-notifications__divider']}>Today</div>
    } else if (isTwoDaysAgo) {
      return <div className={styles['user-notifications__divider']}>2 days ago</div>
    } else  {
      return null
    }
  }

  const urlify = (text) => {
    // eslint-disable-next-line max-len, no-useless-escape
    const urlRegex = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ig;

    const prefix = (url) => url.indexOf("http") === -1 ? "https://" : '';

    return text.replace('(✅)', '<span class="-checkmark-logic"></span>').replace(urlRegex, function(url) {
      return '<a href="' + prefix(url) + url + '" target="_blank">' + url + '</a>';
    })
  }

  const unreadNotifications = notifications?.filter(notification => !notification.is_read);
  const readNotifications = notifications?.filter(notification => notification.is_read);
  const arrayNotifications = unreadNotifications?.concat(readNotifications);

  return (
    <div className={classNames(styles["user-notifications"], 'user-notifications')}>
      {arrayNotifications?.map((notification, index) => {
        const notificationType = notification.notification.notification_type;

        return (
          <>
            {notification.is_read &&
              getDayDivider(
                notification?.notification?.created_at,
                index,
                unreadNotifications?.length,
              )}
            <div
              className={classNames(
                styles['user-notifications__item'],
                notification.is_read ? null : styles['-unread'],
              )}
              onClick={() => {
                if (
                  notification.notification.data.redirect === 'True'
                ) {
                  window.open(
                    notification.notification.data.type === 'formula'
                      ? `/fa-formulas-edit/${notification.notification.data.id}/formula-info`
                      : `/fa/raw-material-view/${notification.notification.data.id}`,
                    '_blank',
                  );
                }
              }}
              onKeyDown={() => {
                if (
                  notification.notification.data.redirect === 'True'
                ) {
                  window.open(
                    notification.notification.data.type === 'formula'
                      ? `/fa-formulas-edit/${notification.notification.data.id}/formula-info`
                      : `/fa/raw-material-view/${notification.notification.data.id}`,
                    '_blank',
                  );
                }
              }}
            >
              <div className={styles['user-notifications__header']}>
                <div className={styles['user-notifications__type']}>
                  {notificationTypeMap[notificationType] || 'GENERAL'}
                </div>
                <div className={styles['user-notifications__date']}>
                  {formatDate(notification.notification.created_at)}
                </div>
              </div>
              <div
                className={styles['user-notifications__body']}
                dangerouslySetInnerHTML={{
                  __html: urlify(notification.notification.body),
                }}
              ></div>
              {notification.notification.notification_type === 'upgrade' ? (
                <a
                  className={styles['user-notifications__btn']}
                  href="https://meetings.hubspot.com/joe-schrank"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Upgrade Access
                </a>
              ) : null}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default NotificationsContent;
