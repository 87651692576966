import { segmentsTrack } from "./trackEvents";

const { sessionStorage } = window;

export const LogOutAction = (showSnackBarAfterRedirect) => {
  const data = JSON.parse(sessionStorage.getItem("user-info-for-verified"));

  const path = window.location.pathname;
  const queryString = window.location.search;

  if (path === '/raw-material-publish' && queryString) {
    window.sessionStorage.setItem('path', `${path}${queryString}`);
  } else {
    window.sessionStorage.setItem('path', path);
  }


  segmentsTrack({
    user: data?.user,
    name: 'User Idled',
    description: 'User Idles',
  });

  window.analytics && window.analytics.reset();
  localStorage.removeItem('LOGIN_EVENT')
  window.localStorage.removeItem('gfp-maintenance-message');
  sessionStorage.removeItem('user-info-for-verified');
  localStorage.removeItem('login');
  localStorage.removeItem('last-seen');
  localStorage.removeItem('sessionTimeStamp');
  window.localStorage.setItem('AUTO_LOGOUT', JSON.stringify({ timestamp: Date.now() }));
  window.location.href = `/${showSnackBarAfterRedirect ? `?showSnackBar=${showSnackBarAfterRedirect}` : ''}`;
  window.sessionStorage.removeItem('Overdue')
}
