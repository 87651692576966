import React, { Component } from 'react';
import 'react-slidedown/lib/slidedown.css';
import classNames from 'classnames';
import { SlideDown as SlideDownVendor } from 'react-slidedown';

import Responsive from '../../utils/Responsive';

import './index.scss';

class SlideDown extends Component {
  static icons = {
    arrow: 'arrow',
    arrowSm: 'arrow-sm',
    plusMinus: 'plus-minus',
    plusMinusLg: 'plus-minus-lg',
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    visible: this.props.defaultVisible || false,
  };

  get isControlled() {
    const { visible } = this.props;

    return visible !== undefined;
  }

  get isShow() {
    // eslint-disable-next-line react/destructuring-assignment
    return this.isControlled ? this.props.visible : this.state.visible;
  }

  get isResponsive() {
    const { workOn } = this.props;

    return workOn !== undefined;
  }

  handleToggle = () => {
    if (this.isControlled) {
      const { id = false, onChange } = this.props;
      onChange(id);
    } else {
      const { visible } = this.state;
      this.setState({
        visible: !visible,
      });
    }
  };

  renderContent(enabled = true) {
    const { header, headerClassName, icon, iconClassName } = this.props;

    let headerContent = null,
      slideContent = null;

    if (header !== undefined) {
      let iconContent = null;

      let headerOpt = {
        className: classNames('o-slide-down__header', headerClassName),
      };

      if (icon && enabled) {
        headerOpt.className += ' o-slide-down__header--has-icon';

        if (this.isShow) {
          headerOpt.className += ' o-slide-down__header--active';
        }

        const { arrow, arrowSm, plusMinus, plusMinusLg } = SlideDown.icons;

        const iconOpt = {
          className: classNames('o-slide-down__icon', iconClassName, {
            'o-slide-down__icon--active': this.isShow,
            'o-slide-down__icon--arrow': icon === arrow,
            'o-slide-down__icon--arrow-sm': icon === arrowSm,
            'o-slide-down__icon--plus-minus':
              icon === plusMinus || icon === plusMinusLg,
            'o-slide-down__icon--plus-minus-lg': icon === plusMinusLg,
          }),
        };

        iconContent = <span {...iconOpt} />;
      }

      if (enabled) {
        headerOpt['onClick'] = this.handleToggle;
      }

      headerContent = (
        <div {...headerOpt}>
          {typeof header === 'function'
              // eslint-disable-next-line react/destructuring-assignment
            ? header(this.state.visible, enabled)
            : header}
          {iconContent}
        </div>
      );
    }

    slideContent = enabled ? (
      <SlideDownVendor transitionOnAppear={false}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {this.isShow ? this.props.children : null}
      </SlideDownVendor>
    ) : (
        // eslint-disable-next-line react/destructuring-assignment
      this.props.children
    );

    return (
      <React.Fragment>
        {headerContent}
        {slideContent}
      </React.Fragment>
    );
  }

  render() {
    const { enabled = true, className, workOn } = this.props;

    return (
      <div
        className={classNames('o-slide-down', className, {
          'o-slide-down--active': this.isShow,
        })}
      >
        {this.isResponsive ? (
          <Responsive match={workOn}>
            {(match) => this.renderContent(match && enabled)}
          </Responsive>
        ) : (
          this.renderContent(enabled)
        )}
      </div>
    );
  }
}

export default SlideDown;
