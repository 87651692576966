import React, {Component} from 'react';
import {Link} from "react-router-dom";
import classNames from 'classnames'

import { ReactComponent as LogoIcon } from '../../assets/img/logo.svg'

import styles from './index.module.scss';

class Logo extends Component {
  render() {

    const { className } = this.props;

    const logoClasses = classNames(styles['logo'], className)

    return (
      <Link to="/" className={logoClasses}>
          <LogoIcon />
      </Link>
    );
  }
}

export default Logo;
