import { closeError } from "./formulator";
import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from "./types";

export const openSnackbar = (condition, msg, errorSnackbar, withBtn, btnText, url) => ({
    type: OPEN_SNACKBAR,
    payload: {
    condition,
    msg,
    errorSnackbar,
    withBtn,
    btnText,
    url,
    }
});

export const closeSnackbar = () => ({
    type: CLOSE_SNACKBAR,
});

export const toggleSnackbar = ( condition, msg, errorSnackbar, withBtn, btnText, url) => {
   let currentTimeout = null

    return (dispatch) => {

      if (currentTimeout) {
            clearTimeout(currentTimeout);
          }

      if (condition) {
        currentTimeout = setTimeout(() => {
          dispatch(openSnackbar(condition, msg, errorSnackbar, withBtn, btnText, url));
        }, 300);
        setTimeout(() => {
          dispatch(closeSnackbar());
        }, 15000);
      } else {
        setTimeout(() => {
          dispatch(closeError());
          dispatch(closeSnackbar())
        }, 300);
      }
    }
  }