import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { catchFunction, getConfig } from 'actions/utils';
import axiosInstance from '../../../actions/axiosInstance';

import { updateTrashCompoundIngredients } from '../CompoundIngredientsUpdate/slice';
import { deleteTrashCompoundIngredients } from '../CompoundIngredientsDelete/slice';
import { updateTrashCompoundIngredientsBulk } from '../CompoundIngredientsUpdateBulk/slice';

export const initialState = {
  сompoundIngredients: undefined,
  error: undefined,
  isLoading: false,
};

export const getTrashCompoundIngredients = createAsyncThunk(
  'get_compound_ingredients',
  async (sort, { dispatch, rejectWithValue }) => {
    const sortQuery = sort ? `?sort=${sort}` : '';
    try {
      const response = await axiosInstance.get(
        `/rest_api/trash/compound_ingredients${sortQuery}`,
        getConfig(),
      );

      return response.data.data;
    } catch (error) {
      catchFunction({
        error,
        dispatch,
        refreshFunction: getTrashCompoundIngredients(),
      });

      return rejectWithValue(error.message);
    }
  },
);

export const compoundIngredientsTrash = createSlice({
  name: 'get_compound_ingredients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrashCompoundIngredients.pending, (state) => {
        state.isLoading = true;
        state.сompoundIngredients = undefined;
        state.error = undefined;
      })
      .addCase(getTrashCompoundIngredients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.сompoundIngredients = action.payload;
      })
      .addCase(getTrashCompoundIngredients.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateTrashCompoundIngredientsBulk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.сompoundIngredients = action.payload
      })
      .addCase(updateTrashCompoundIngredients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.сompoundIngredients = state.сompoundIngredients.map((trash) => {
          if (trash.id === action.payload.id) {
            return action.payload;
          }

          return trash;
        });
      })
      .addCase(deleteTrashCompoundIngredients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.сompoundIngredients = state.сompoundIngredients?.filter(
          (trash) => {
            return trash.id !== action.payload.id;
          },
        );
      });
  },
});

export default compoundIngredientsTrash.reducer;
