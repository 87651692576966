export const PAGINATION_FOLDERS_MAX = 50;
export const PAGINATION_RAWMATERIAL_MAX = 50;
export const PAGINATION_REPLACEMENT_GROUPS_MAX = 50;
export const PAGINATION_POLICIES_MAX = 50;
export const PAGINATION_CONSUMERDBS_MAX = 50;
export const PAGINATION_REPORTS_MAX = 50;
export const PAGINATION_TNL_MAX = 100;
export const PAGINATION_USER_MAX = 50;
export const PAGINATION_GROUPS_MAX = 50;

export const DND_ITEM_TYPE = {
  ROW: 'row'
};
