import React from 'react';
import classNames from 'classnames';

import image_1 from '../../assets/img/UserGuide/Guide-img-1.png';
import image_2 from '../../assets/img/UserGuide/Guide-img-2.png';
import image_3 from '../../assets/img/UserGuide/Guide-img-3.png';
import image_4 from '../../assets/img/UserGuide/Guide-img-4.png';
import image_5 from '../../assets/img/UserGuide/Guide-img-5.png';
import image_6 from '../../assets/img/UserGuide/Guide-img-6.png';
import image_7 from '../../assets/img/UserGuide/Guide-img-7.png';
import image_8 from '../../assets/img/UserGuide/Guide-img-8.png';
import image_9 from '../../assets/img/UserGuide/Guide-img-9.png';
import image_10 from '../../assets/img/UserGuide/Guide-img-10.png';
import image_11 from '../../assets/img/UserGuide/Guide-img-11.png';
import image_12 from '../../assets/img/UserGuide/Guide-img-12.png';
import image_13 from '../../assets/img/UserGuide/Guide-img-13.png';
import image_14 from '../../assets/img/UserGuide/Guide-img-14.png';

import styles from './index.module.scss';
import UserGuidAccordion from './UserGuidAccordion';

const UserGuidContent = () => {
  return (
    <div className={classNames(styles['user-guide'])}>
      <UserGuidAccordion
        headerContent={
          <div className={styles['user-guide__header']}>
            <div className={styles['number']}>1</div>
            <div className={styles['text']}>Create a Folder</div>
          </div>
        }
        content={
          <>
            <div className={styles['user-guide__mainDescription']}>
              Folders are used to store and organize your product formulas.
            </div>
            <div className={styles['user-guide__subHeading']}>Add a Folder</div>
            <div className={styles['user-guide__additionalDescription']}>
              Add your first folder using the <b>"Add Folder"</b> button and
              name the folder.
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_1} alt="" />
            </div>
            <div className={styles['user-guide__subHeading']}>
              Edit the Folder
            </div>
            <div className={styles['user-guide__additionalDescription']}>
              To rename this folder, simply click the name of your folder to
              edit it.
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_2} alt="" />
            </div>
          </>
        }
      />
      <UserGuidAccordion
        headerContent={
          <div className={styles['user-guide__header']}>
            <div className={styles['number']}>2</div>
            <div className={styles['text']}>Add a Product Formula</div>
          </div>
        }
        content={
          <>
            <div className={styles['user-guide__mainDescription']}>
              You can now add a product formula to your folder.
            </div>
            <div className={styles['user-guide__subHeading']}>
              Create New Formula
            </div>
            <div className={styles['user-guide__additionalDescription']}>
              Add your first product formula using the{' '}
              <b>"Create New Formula"</b> button.
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_3} alt="" />
            </div>
            <div className={styles['user-guide__subHeading']}>
              Add Formula Info
            </div>
            <div className={styles['user-guide__additionalDescription']}>
              In the left side toolbar complete the required and optional fields
              in the formula info tab, then press <b>"Apply"</b>.
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_4} alt="" />
            </div>
          </>
        }
      />
      <UserGuidAccordion
        headerContent={
          <div className={styles['user-guide__header']}>
            <div className={styles['number']}>3</div>
            <div className={styles['text']}>Add Ingredients</div>
          </div>
        }
        content={
          <>
            <div className={styles['user-guide__mainDescription']}>
              After adding formula info, you can add ingredients to the product
              formula.
            </div>
            <div className={styles['user-guide__subHeading']}>
              Add INCIs to the Formula
            </div>
            <div className={styles['user-guide__additionalDescription']}>
              Navigate to the Ingredients tab on the left side toolbar and paste
              in the ingredients list. Use simple ingredient names and don’t
              include adjectives like “organic, sustainable, or natural”
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_5} alt="" />
            </div>
            <div className={styles['user-guide__subHeading']}>
              <div className={styles['user-guide__warning']} />
              <div>Ensure Ingredients are Valid</div>
            </div>
            <div className={styles['user-guide__additionalDescription']}>
              Invalid ingredients will appear in the table with a warning
              symbol. Check spelling and naming before clicking{' '}
              <b>“Request Review”</b>. Requested ingredients will be reviewed
              within 1 business day.
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_6} alt="" />
            </div>
          </>
        }
      />
      <UserGuidAccordion
        headerContent={
          <div className={styles['user-guide__header']}>
            <div className={styles['number']}>4</div>
            <div className={styles['text']}>Screen for Compliance</div>
          </div>
        }
        content={
          <>
            <div className={styles['user-guide__mainDescription']}>
              After populating ingredients in the table, you’re ready to screen
              for compliance.
            </div>
            <div className={styles['user-guide__subHeading']}>
              Select Compliance Standards
            </div>
            <div className={styles['user-guide__additionalDescription']}>
              Navigate to <b>“Retail Standards”</b> on the compliance bar at the
              top of the page. Click “Select all regulations” and then press{' '}
              <b>“Screen”</b>.
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_7} alt="" />
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_8} alt="" />
            </div>
            <div className={styles['user-guide__subHeading']}>
              Review Results
            </div>
            <div className={styles['user-guide__additionalDescription']}>
              Compliant ingredients will turn green and non-compliant ingredients will turn red. Hover your cursor over each warning flag for more information.
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_9} alt="" />
            </div>
          </>
        }
      />
      <UserGuidAccordion
        headerContent={
          <div className={styles['user-guide__header']}>
            <div className={styles['number']}>5</div>
            <div className={styles['text']}>Resolve Compliance Issues</div>
          </div>
        }
        content={
          <>
            <div className={styles['user-guide__mainDescription']}>
              Red ingredients are often resolved by providing more information.
            </div>
            <div className={styles['user-guide__subHeading']}>
              Add % Concentration
            </div>
            <div className={styles['user-guide__additionalDescription']}>
              If the warning flag includes a % cutoff, click on the
              concentration of that ingredient to enter the concentration for
              that ingredient. If the entered % is within the compliance limit,
              the ingredient will turn green.
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_10} alt="" />
            </div>
            <div className={styles['user-guide__subHeading']}>
              Add Documents (optional)
            </div>
            <div className={styles['user-guide__additionalDescription']}>
              If the warning flag includes additional requirements, you may
              attach documents to substantiate those requirements. Navigate to{' '}
              <b>“Formula Documents”</b> on the left side toolbar and upload the
              required document.
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_11} alt="" />
            </div>
          </>
        }
      />
      <UserGuidAccordion
        headerContent={
          <div className={styles['user-guide__header']}>
            <div className={styles['number']}>6</div>
            <div className={styles['text']}>
              Share Product Formulas with Partners
            </div>
          </div>
        }
        content={
          <>
            <div className={styles['user-guide__mainDescription']}>
              Finally, you’ll want to share your product formula(s) with your
              partner(s).
            </div>
            <div className={styles['user-guide__subHeading']}>
              Share one Product Formula
            </div>
            <div className={styles['user-guide__additionalDescription']}>
              In the formula editor, navigate to the share button in the upper
              right corner.
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_12} alt="" />
            </div>

            <div className={styles['user-guide__additionalDescription']}>
              Select the partner you’d like to share with, and decide whether
              you’d like the partner to see the percent concentrations and/or
              attached documents.
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_13} alt="" />
            </div>
            <div className={styles['user-guide__additionalPanel']}>
              You can bulk share formulas from <b>“My Formulas”</b>. Select all the formulas you’d like to share then
              click “share” on the action bar on the bottom of the page.
            </div>
            <div className={styles['user-guide__subHeading']}>
              Confirm Sharing
            </div>
            <div className={styles['user-guide__additionalDescription']}>
              Shared product formulas can be viewed in <b>“My Partners”</b>.
              Formulas that you have shared will appear in “Sharing”. Click{' '}
              <b>“View”</b> to see the partner’s view of your formula.
            </div>
            <div className={styles['user-guide__image']}>
              <img src={image_14} alt="" />
            </div>
          </>
        }
      />
    </div>
  );
};

export default UserGuidContent;
