import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

const Input = (props) => {
  const {className, type = 'text', ...rest} = props;

  if (type === 'textarea') {
    return <textarea className={classNames(styles['input'], className)} {...rest}/>
  }

  return <input type={type} className={classNames(styles['input'], className)}  {...rest}/>;
};

export default Input;
