import React from 'react';
import {connect} from "react-redux";
import {closePromoBar} from "actions";
import {SlideDown} from "react-slidedown";
import 'react-slidedown/lib/slidedown.css'

import DownloadAppButton from "../../DownloadAppButton";
import config from "../../../config";

import styles from './index.module.scss';

const PromoBar = ({isOpen, close, additionalClassName}) => (
  <SlideDown transitionOnAppear={false}>
    {isOpen &&
    <div className={`${styles['holder']} ${config.analytic.classes.promoBar} page-theme-v2 ${styles[additionalClassName]}`}>
      <div className={styles['holder-inner']}>
        <DownloadAppButton link={config.promoBarLink} className={styles['app-btn']} icon={false} checkDisable>
            GFP Awarded Best Breakthrough Technology by BeautyMatter. Read More Here.
        </DownloadAppButton>
      </div>
      <div
        className={styles['close']}
        onClick={close}
        onKeyDown={close}
      />
    </div>
    }
  </SlideDown>
);

export default connect(
  state => ({
    isOpen: state.general.promoBar
  }),
  dispatch => ({
    close: () => dispatch(closePromoBar())
  })
)(PromoBar);
