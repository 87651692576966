export function setScrolledHeader() {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
        let currentScrollPos = window.pageYOffset;
        const fixedHeader = document.getElementById("header-fixed");

        if (fixedHeader) {
            if (currentScrollPos <= 0 || prevScrollpos >= currentScrollPos) {
                // document.getElementById("header-fixed").style.top = "0";
                document.getElementById("header-fixed").classList.remove('-scrolled');

                if (!currentScrollPos) {
                    document.getElementById("header-fixed").style.background = "transparent";
                    document.getElementById("header-fixed").style.top = null;
                } else {
                    document.getElementById("header-fixed").style.background = "#ffffff";
                    document.getElementById("header-fixed").style.top = "0";
                }
            } else {
                // document.getElementById("header-fixed").style.top = "-80.59px";
                document.getElementById("header-fixed").classList.add('-scrolled');

            }

            prevScrollpos = currentScrollPos;
        }
    }

}
