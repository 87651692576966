import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {catchFunction, getConfig} from 'actions/utils';
import axiosInstance from '../../../actions/axiosInstance';

import {updateTrashFormulatorFolders} from '../FormulatorFoldersUpdate/slice';
import {deleteTrashFormulatorFolders} from '../FormulatorFoldersDelete/slice';
import {deleteTrashFormulatorFoldersBulk} from '../FormulatorFoldersDeleteBulk/slice';

export const initialState = {
  formulatorFolders: undefined,
  error: undefined,
  isLoading: false,
};

export const getTrashFormulatorFolders = createAsyncThunk(
  'get_formulator_folders',
  async (param, {dispatch, rejectWithValue}) => {
    const {folder_id, sort} = param;
    const id = folder_id ? `?folder_id=${folder_id}` : '';
    const sortQuery = sort ? (id ? `&sort=${sort}` : `?sort=${sort}`) : '';
    try {
      const response = await axiosInstance.get(
        `/rest_api/trash/formulator/folders${id}${sortQuery}`,
        getConfig(),
      );

      return {data: response.data, folderId: folder_id};
    } catch (error) {
      catchFunction({
        error,
        dispatch,
        refreshFunction: getTrashFormulatorFolders(),
      });

      return rejectWithValue(error.message);
    }
  },
);

export const formulatorFoldersTrash = createSlice({
  name: 'get_formulator_folders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrashFormulatorFolders.pending, (state) => {
        state.isLoading = true;
        state.formulatorFolders = undefined;
        state.error = undefined;
      })
      .addCase(getTrashFormulatorFolders.fulfilled, (state, action) => {
        state.isLoading = false;

        if (!action.payload.folderId) {
          state.formulatorFolders = action.payload.data;
        }
      })
      .addCase(getTrashFormulatorFolders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateTrashFormulatorFolders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.formulatorFolders = action.payload
        // state.formulatorFolders = state.form ulatorFolders.map((trash) => {
        //   if (trash.id === action.payload.id) {
        //     return action.payload;
        //   }
        //
        //   return trash;
        // });
      })
      .addCase(deleteTrashFormulatorFolders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.formulatorFolders = state.formulatorFolders?.filter(
          (trash) => {
            return trash.id !== action.payload.id;
          },
        );
      })
      .addCase(deleteTrashFormulatorFoldersBulk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.formulatorFolders = state.formulatorFolders?.filter(
          (trash) => {
            return trash.id !== action.payload.id;
          },
        );
      });
  },
});

export default formulatorFoldersTrash.reducer;
