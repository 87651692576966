import styles from "../Management/index.module.scss";
import React, { useRef, useState } from "react";
import classNames from 'classnames';

export const CutTextRender = ({
  text,
  bottom,
}) => {
  const [showOverflow, setShowOverflow] = useState({ top: 0, left: 0, maxWidth: undefined });
  const [showFullName, setShowFullName] = useState(false);
  const ref = useRef(null);
  const commentRef = useRef(null);

  const getStyles = () => {
    const res = {
      left: showOverflow.left,
      maxWidth: showOverflow.maxWidth,
    }

    if (bottom) {
      res.top = showOverflow.top;
    } else {
      res.bottom = showOverflow.top;
    }

    return res;
  }

  return (
    <div
      ref={ref}
      className={styles['overflowHidden']}
      onMouseEnter={(e) => {
        if (!showFullName && ref.current?.clientWidth < ref.current?.scrollWidth) {
          setShowFullName(true);
        } else if (showFullName && ref.current?.clientWidth >= ref.current?.scrollWidth) {
          setShowFullName(false);
        }

        const t = e.target.getBoundingClientRect();
        const parentBlockWidth = t.right - t.left;
        const halfWidth = t.left + (parentBlockWidth) / 2;
        const maxWidthFromRight = 2 * ((parentBlockWidth) / 2 + window.innerWidth - t.right) - 40;
        const maxWidthFromLeft = 2 * halfWidth - 60;
        const resultWidth = maxWidthFromLeft > maxWidthFromRight ? maxWidthFromRight : maxWidthFromLeft;

        setShowOverflow({
          top: `${bottom ? t.bottom : window.innerHeight - t.top}px`,
          left: `${halfWidth}px`,
          maxWidth: `${resultWidth}px`,
        });
      }}
      onMouseLeave={() => {
        setShowOverflow({ top: 0, left: 0 });
      }}
    >
      {text}
      {showFullName &&
        <div
          ref={commentRef}
          className={classNames(styles['f-admin__table-warning-comment'], bottom && styles['bottom'])}
          style={getStyles()}
        >
          <div
            className={styles['-scroll']}
            dangerouslySetInnerHTML={{
              __html:
                text.replaceAll(
                  '\n',
                  '<br/>',
                ),
            }}
          />
        </div>
      }
    </div>
  )
}