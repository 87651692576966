import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { catchFunction, getConfig } from 'actions/utils';
import axiosInstance from '../../../actions/axiosInstance';


export const initialState = {
  сompoundIngredients: undefined,
  error: undefined,
  isLoading: false,
};
export const updateTrashCompoundIngredients = createAsyncThunk(
  'update_compound_ingredients',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/rest_api/trash/compound_ingredients/${id}`,
          getConfig(),
      );

      return response.data;
    } catch (error) {
      catchFunction({
        error,
        dispatch,
        refreshFunction: updateTrashCompoundIngredients(id),
      });

      return rejectWithValue(error.message);
    }
  },
);

export const updateCompoundIngredientsTrash = createSlice({
  name: 'update_compound_ingredients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateTrashCompoundIngredients.pending, (state) => {
        state.isLoading = true;
        state.сompoundIngredients = undefined;
        state.error = undefined;
      })
      .addCase(updateTrashCompoundIngredients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.сompoundIngredients = action.payload;
      })
      .addCase(updateTrashCompoundIngredients.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default updateCompoundIngredientsTrash.reducer;
