export const URLS_DETECT = {
    '/about': 'About',
    '/careers': 'Careers',
    '/compare': 'Compare',
    '/consumers/faq': 'Consumer FAQ',
    '/face-it-mag': 'Consumer Blog',
    '/shop': 'Research + Shop',
    '/retailers': 'Retailers',
    '/faq': 'FAQ',
    '/contractmanufacturers': 'Formulators & Manufacturers',
    '/ingredientsuppliers': 'Ingredient Suppliers',
    '/consumerstudies': 'Claim Validation',
    '/': 'Home page',
    '/accounts/login?type=brand&next=/sa/products': 'Login Page',
    '/brands': 'Brands',
    '/blog': 'Blog',
}
