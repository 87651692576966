import React from 'react';
import SectionBulma from 'react-bulma-components/lib/components/section';
import ContainerBulma from 'react-bulma-components/lib/components/container';
import Columns from 'react-bulma-components/lib/components/columns';
import classNames from 'classnames'

import styles from './index.module.scss'

const Section = ({className, ...rest}) => (
  <SectionBulma className={classNames(styles.section, className)} {...rest}/>
);

const Container = ({className, size = '', ...rest}) => (
  <ContainerBulma className={classNames(styles.container, className, {
    [styles['container--sm']]: size === 'sm',
    [styles['container--md']]: size === 'md'
  })} {...rest}/>
);


const Row = Columns,
  Column = Columns.Column;

export {
  Section,
  Container,
  Columns,
  Row,
  Column
};
