import { SET_FEATURE_FLAGS } from 'actions/types';

import {GET_USER_STATUS} from '../actions';


export default function reducer(state = {isLoggedIn: false, isSuperAdmin: false}, action) {
  switch (action.type) {
    case GET_USER_STATUS:

      const {type, ...rest} = action.data;

      return {
        ...rest,
        isLoggedIn: true,
        isSuperAdmin: true
      };
    case SET_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: action.data
      }
    default:
      return state;
  }
}


