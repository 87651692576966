import React, {useEffect} from 'react';
import classNames from 'classnames';

// Tmp Normalize styles in page component
import '../../scss/normalize.scss'

const PageBase = ({children, className, version = 1, id}) => {

  // Scroll to hash on page load.
  useEffect(() => {
    let hash = window.location.hash;

    if (hash && hash !== '#/') {
      const interval = setInterval(function () {
        let element = document.querySelector(hash);

        if (element) {
          element.scrollIntoView();
          clearInterval(interval);
        }
      }, 500);
      setTimeout(() => clearInterval(interval), 5000);
    }
  }, []);

  return (
    <div id={id} className={classNames(`page-theme-v${version}`, className)}>
      {children}
    </div>
  );
};

export default PageBase;
