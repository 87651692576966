import React, { Component } from 'react';
import classNames from 'classnames';

// Grade Icons
import { ReactComponent as GradeA } from '../../assets/img/Grades/gradeA.svg';
import { ReactComponent as GradeB } from '../../assets/img/Grades/gradeB.svg';
import { ReactComponent as GradeC } from '../../assets/img/Grades/gradeC.svg';
import { ReactComponent as GradeD } from '../../assets/img/Grades/gradeD.svg';
import { ReactComponent as GradeE } from '../../assets/img/Grades/gradeE.svg';
import { ReactComponent as GradeF } from '../../assets/img/Grades/gradeF.svg';
import { ReactComponent as Approved } from '../../assets/img/Grades/approved.svg';
// IngredientGrade Icons
import { ReactComponent as IngredientGradeA } from '../../assets/img/Grades/ingredientgradeA.svg';
import { ReactComponent as IngredientGradeB } from '../../assets/img/Grades/ingredientgradeB.svg';
import { ReactComponent as IngredientGradeC } from '../../assets/img/Grades/ingredientgradeC.svg';
import { ReactComponent as IngredientGradeD } from '../../assets/img/Grades/ingredientgradeD.svg';
import { ReactComponent as IngredientGradeE } from '../../assets/img/Grades/ingredientgradeE.svg';
import { ReactComponent as IngredientGradeF } from '../../assets/img/Grades/ingredientgradeF.svg';
// Benefits Icons
import { ReactComponent as BenefitBoostCollagen } from '../../assets/img/Icons/Benefits/BoostCollagen.svg';
import { ReactComponent as BenefitDarkSpots } from '../../assets/img/Icons/Benefits/DarkSpots.svg';
import { ReactComponent as BenefitHealing } from '../../assets/img/Icons/Benefits/Healing.svg';
import { ReactComponent as BenefitMoisturizes } from '../../assets/img/Icons/Benefits/Moisturizes.svg';
import { ReactComponent as BenefitPores } from '../../assets/img/Icons/Benefits/Pores.svg';
import { ReactComponent as BenefitSootheSensitive } from '../../assets/img/Icons/Benefits/SootheSensitive.svg';
import { ReactComponent as BenefitUVFilter } from '../../assets/img/Icons/Benefits/UVFilter.svg';
import { ReactComponent as BenefitWrinkles } from '../../assets/img/Icons/Benefits/Wrinkles.svg';
import { ReactComponent as BenefitPHBalance } from '../../assets/img/Icons/Benefits/PHBalance.svg';
import { ReactComponent as BenefitBrightenTones } from '../../assets/img/Icons/Benefits/BrightenTones.svg';
// Toxin Icons
import { ReactComponent as ToxinAllergens } from '../../assets/img/Icons/Toxins/Allergens.svg';
import { ReactComponent as ToxinAllergensDisabled } from '../../assets/img/Icons/Toxins/AllergensDisabled.svg';
import { ReactComponent as ToxinCarcinogens } from '../../assets/img/Icons/Toxins/Carcinogens.svg';
import { ReactComponent as ToxinCarcinogensDisabled } from '../../assets/img/Icons/Toxins/CarcinogensDisabled.svg';
import { ReactComponent as ToxinDisruptors } from '../../assets/img/Icons/Toxins/Disruptors.svg';
import { ReactComponent as ToxinDisruptorsDisabled } from '../../assets/img/Icons/Toxins/DisruptorsDisabled.svg';
import { ReactComponent as ToxinIrritants } from '../../assets/img/Icons/Toxins/Irritants.svg';
import { ReactComponent as ToxinIrritantsDisabled } from '../../assets/img/Icons/Toxins/IrritantsDisabled.svg';
// Social
import { ReactComponent as SocialInstagram } from '../../assets/img/Icons/Social/Instagram.svg';
import { ReactComponent as SocialTwitter } from '../../assets/img/Icons/Social/Twitter.svg';
import { ReactComponent as SocialFacebook } from '../../assets/img/Icons/Social/Facebook.svg';
import { ReactComponent as SocialPinterest } from '../../assets/img/Icons/Social/Pinterest.svg';
import { ReactComponent as SocialLinkedIn } from '../../assets/img/Icons/Social/Linkedin.svg';
// Others
import { ReactComponent as InfoLetter } from '../../assets/img/Icons/Misc/Info.svg';
import { ReactComponent as Search } from '../../assets/img/Icons/Misc/Search.svg';
import { ReactComponent as ArrowRegular } from '../../assets/img/Icons/Misc/ArrowRegular.svg';
import { ReactComponent as HamburgerMenu } from '../../assets/img/Icons/Misc/HamburgerMenu.svg';
import { ReactComponent as Close } from '../../assets/img/Icons/Misc/Close.svg';
import { ReactComponent as Share } from '../../assets/img/Icons/Misc/Share.svg';
import { ReactComponent as ArrowSolidSmall } from '../../assets/img/Icons/Misc/ArrowSolidSmall.svg';

import './index.scss';

class Icon extends Component {
  chooseIcon(name) {
    switch (name) {
      // Grade Icons
      case 'GradeA':
        return <GradeA />;
      case 'GradeB':
        return <GradeB />;
      case 'GradeC':
        return <GradeC />;
      case 'GradeD':
        return <GradeD />;
      case 'GradeE':
        return <GradeE />;
      case 'GradeF':
        return <GradeF />;
      case 'Approved':
        return <Approved />;

      // IngredientGrade Icons
      case 'IngredientGradeA':
        return <IngredientGradeA />;
      case 'IngredientGradeB':
        return <IngredientGradeB />;
      case 'IngredientGradeC':
        return <IngredientGradeC />;
      case 'IngredientGradeD':
        return <IngredientGradeD />;
      case 'IngredientGradeE':
        return <IngredientGradeE />;
      case 'IngredientGradeF':
        return <IngredientGradeF />;

      // Benefit Icons
      case 'BenefitBoostCollagen':
        return <BenefitBoostCollagen />;
      case 'BenefitDarkSpots':
        return <BenefitDarkSpots />;
      case 'BenefitHealing':
        return <BenefitHealing />;
      case 'BenefitMoisturizes':
        return <BenefitMoisturizes />;
      case 'BenefitPores':
        return <BenefitPores />;
      case 'BenefitSootheSensitive':
        return <BenefitSootheSensitive />;
      case 'BenefitUVFilter':
        return <BenefitUVFilter />;
      case 'BenefitWrinkles':
        return <BenefitWrinkles />;
      case 'BenefitPHBalance':
        return <BenefitPHBalance />;
      case 'BenefitBrightenTones':
        return <BenefitBrightenTones />;

      // Toxin Icons
      case 'toxin_allergen':
        return <ToxinAllergens />;
      case 'toxin_allergen_disabled':
        return <ToxinAllergensDisabled />;
      case 'toxin_carcinogen':
        return <ToxinCarcinogens />;
      case 'toxin_carcinogen_disabled':
        return <ToxinCarcinogensDisabled />;
      case 'toxin_hormonal':
        return <ToxinDisruptors />;
      case 'toxin_hormonal_disabled':
        return <ToxinDisruptorsDisabled />;
      case 'toxin_irritant':
        return <ToxinIrritants />;
      case 'toxin_irritant_disabled':
        return <ToxinIrritantsDisabled />;

      // Social
      case 'SocialInstagram':
        return <SocialInstagram />;
      case 'SocialTwitter':
        return <SocialTwitter />;
      case 'SocialFacebook':
        return <SocialFacebook />;
      case 'SocialPinterest':
        return <SocialPinterest />;
      case 'SocialLinkedIn':
        return <SocialLinkedIn />;

      // Others
      case 'Info':
        return <InfoLetter />;
      case 'Search':
        return <Search />;
      case 'ArrowRegular':
        return <ArrowRegular />;
      case 'HamburgerMenu':
        return <HamburgerMenu />;
      case 'Close':
        return <Close />;
      case 'Share':
        return <Share />;
      case 'ArrowSolidSmall':
        return <ArrowSolidSmall />;

      default:
        return false;
    }
  }

  render() {
    const { className, tooltip, name, disabled, ...rest } = this.props;

    const iconClass = classNames('svg-icon', className, `svg-icon-${name}`, {
      'svg-icon--disabled': disabled,
    });

    let opts = {};

    if (tooltip !== undefined) {
      opts['data-tooltip'] = tooltip;
    }

    const Icon = name ? this.chooseIcon(name) : false;

    return Icon !== false ? (
      <div className={iconClass} {...opts} {...rest}>
        {Icon}
      </div>
    ) : null;
  }
}

export default Icon;
