import React, { useState } from 'react';
import { connect } from 'react-redux';
import { closeRequestReviewPopup } from 'actions';
import { useFormik } from 'formik';
import { object as YupObject, string as YupString } from 'yup';
import config from 'config';
import { validate } from 'utils/form';

import Modal from '../../Modal';
import Loader from '../../Loader';
import Button from '../../Button_v2';
import Input from '../../Input_v2';
import Heading from '../../Heading_v2';

import styles from './index.module.scss';
import axiosInstance from '../../../actions/axiosInstance';

const ValidationSchema = YupObject({
  product: YupString().required('required'),
  content: YupString(),
  email: YupString().email('invalid email'),
});

const RequestReview = (props) => {
  const { isOpen, closePopup } = props,
    [isSending, setIsSending] = useState(false),
    [isSent, setIsSent] = useState(false);

  const form = useFormik({
    initialValues: {
      product: '',
      content: '',
      email: '',
    },
    onSubmit: async ({ product, content, email }) => {
      setIsSending(true);

      try {
        await axiosInstance.post('/publicapi/user/send_inquiry/', {
          name: 'Product analysis request',
          email,
          // eslint-disable-next-line max-len
          content: `Hello! I am interested in the safety of product "${product}". Please review it and send me a notification! ${content}`,
        });
        setIsSent(true);
      } catch (e) {
        alert('Sending error');
      } finally {
        setIsSending(false);
      }
    },
    validate: validate(ValidationSchema),
  });

  const onRequestClose = () => {
    closePopup();

    if (isSent) {
      form.resetForm();
      setIsSent(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className={styles['modal']}>
        {isSending && <Loader cover transparent />}

        {!isSent ? (
          <>
            <Heading as="h2" className={styles['heading']}>
              Request Product Review
            </Heading>

            <form
              onSubmit={form.handleSubmit}
              className={config.analytic.classes.requestReviewPopupForm}
            >
              <Input
                name="product"
                value={form.values.product}
                onChange={form.handleChange}
                placeholder="Product name or link"
              />

              <Input
                name="content"
                type="textarea"
                value={form.values.content}
                onChange={form.handleChange}
                placeholder="Message"
                rows="5"
              />

              <Input
                name="email"
                value={form.values.email}
                onChange={form.handleChange}
                type="email"
                placeholder="Email"
              />
              <span>We'll never share your email with anyone else.</span>

              <Button
                disabled={!form.dirty || !form.isValid}
                className={config.analytic.classes.requestReviewPopupFormBtn}
              >
                Request
              </Button>
            </form>
          </>
        ) : (
          <div className={styles['success-msg']}>
            <Heading as="h2">
              Thank you for contacting the Good Face Project!
            </Heading>
            <p>We will be in touch shortly.</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default connect(
  (state) => ({
    isOpen: state.general.requestReviewPopup,
  }),
  (dispatch) => ({
    closePopup: () => dispatch(closeRequestReviewPopup()),
  }),
)(RequestReview);
