import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { catchFunction, getConfig } from 'actions/utils';
import axiosInstance from '../../../actions/axiosInstance';

export const initialState = {
  consumerProducts: undefined,
  error: undefined,
  isLoading: false,
};

export const deleteTrashConsumerProductsBulk = createAsyncThunk(
  'delete_consumer_products_bulk',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/rest_api/trash/products/bulk`, getConfig(data))

      return response.data;
    } catch (error) {
      catchFunction({
        error,
        dispatch,
        refreshFunction: deleteTrashConsumerProductsBulk(data),
      });

      return rejectWithValue(error.message);
    }
  },
);

export const deleteConsumerProductsTrashBulk = createSlice({
  name: 'delete_consumer_products_bulk',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteTrashConsumerProductsBulk.pending, (state) => {
        state.isLoading = true;
        state.consumerProducts = undefined;
        state.error = undefined;
      })
      .addCase(deleteTrashConsumerProductsBulk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.consumerProducts = action.payload;
      })
      .addCase(deleteTrashConsumerProductsBulk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default deleteConsumerProductsTrashBulk.reducer;
