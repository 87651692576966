import axiosInstance from './axiosInstance';

import {
    DELETE_COMPANY_USER, DELETE_CUSTOM_METHOD_SEARCH,
    GET_FORMULA_SPECIFICATIONS,
    GET_SPECIFICATION_METHODS,
    GET_SPECIFICATION_METHODS_SEARCH, UPDATE_PRODUCT_DBS,
    UPDATE_SPECIFICATIONS_LIST
} from './types';
import { catchFunction, getConfig } from './utils';

export function getSpecifications(formulaId) {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/formulator/formulas/${formulaId}/specifications`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULA_SPECIFICATIONS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getSpecifications(formulaId),
        });
      });
  };
}

export function getSpecificationMethods(formulaId, specId) {
  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/formulator/formulas/${formulaId}/specifications/${specId}/test-methods`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: GET_SPECIFICATION_METHODS,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getSpecificationMethods(formulaId, specId),
          });
        });
  };
}

export function getSpecificationMethodsSearch(formulaId, specId) {
  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/formulator/formulas/${formulaId}/specifications/${specId}/test-methods/search`,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: GET_SPECIFICATION_METHODS_SEARCH,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getSpecificationMethodsSearch(formulaId, specId),
          });
        });
  };
}

export function getSpecificationMethod(formulaId, specId, methodId) {
  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/formulator/formulas/${formulaId}/specifications/${specId}/test-methods/${methodId}`,
            getConfig(),
        )
        .then((response) => {
          return { ...response.data, headers: response.headers };
        })
        .then((response) => {
          dispatch({
            type: GET_SPECIFICATION_METHODS_SEARCH,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getSpecificationMethod(formulaId, specId, methodId),
          });
        });
  };
}

export function updateSpecificationsList(formulaId, specIds) {
  return (dispatch) => {
    return axiosInstance
        .post(`/rest_api/formulator/formulas/${formulaId}/specifications`, { specification_ids: specIds }, getConfig())
        .then((response) => {
          return { ...response.data, headers: response.headers };
        })
        .then((response) => {
          dispatch({
            type: UPDATE_SPECIFICATIONS_LIST,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: updateSpecificationsList(formulaId, specIds),
          });
        });
  };
}

export function createSpecificationMethod(formulaId, specId, data) {
  return (dispatch) => {
    return axiosInstance
        .post(`/rest_api/formulator/formulas/${formulaId}/specifications/${specId}/test-methods`, data, getConfig())
        .then((response) => {
          return { ...response.data, headers: response.headers };
        })
        .then((response) => {
          dispatch({
            type: UPDATE_SPECIFICATIONS_LIST,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: createSpecificationMethod(formulaId, specId, data),
          });
        });
  };
}

export function updateSpecificationMethod(formulaId, specId, methodId, data) {
  return (dispatch) => {
    return axiosInstance
        .put(`/rest_api/formulator/formulas/${formulaId}/specifications/${specId}/test-methods/${methodId}`, data, getConfig())
        .then((response) => {
          return { ...response.data, headers: response.headers };
        })
        .then((response) => {
          dispatch({
            type: UPDATE_PRODUCT_DBS,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: updateSpecificationMethod(formulaId, specId, methodId, data),
          });
        });
  };
}

export function deleteSpecMethod(formulaId, specId, methodId) {
  return (dispatch) => {
    return axiosInstance
        .delete(`/rest_api/formulator/formulas/${formulaId}/specifications/${specId}/test-methods/${methodId}`, getConfig())
        .then((response) => {
          return { ...response.data, headers: response.headers };
        })

        .then((response) => {
          dispatch({
            type: DELETE_COMPANY_USER,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: deleteSpecMethod(formulaId, specId, methodId),
          });
        });
  };
}

export function createNewTestMethodSearch(formulaId, specId, data) {
  return (dispatch) => {
    return axiosInstance
        .post(`/rest_api/formulator/formulas/${formulaId}/specifications/${specId}/test-methods/search/add`, data, getConfig())
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: UPDATE_SPECIFICATIONS_LIST,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: createNewTestMethodSearch(formulaId, specId, data),
          });
        });
  };
}

export function deleteCustomTestMethodSearch(formulaId, specId, data) {
  return (dispatch) => {
    return axiosInstance
        .delete(`/rest_api/formulator/formulas/${formulaId}/specifications/${specId}/test-methods/search/delete`, getConfig(data))
        .then((response) => {
          return response.data;
        })

        .then((response) => {
          dispatch({
            type: DELETE_COMPANY_USER,
            data: response,
          });

          return DELETE_CUSTOM_METHOD_SEARCH
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: deleteCustomTestMethodSearch(formulaId, specId, data),
          });
        });
  };
}



