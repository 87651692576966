import React from 'react';
import ReactDOM from "react-dom";

import Footer from '../../components/Footer'

const FooterPortal = () => ReactDOM.createPortal(
  <Footer/>,
  document.getElementById('footer'),
);

export default FooterPortal;
