import axiosInstance from './axiosInstance';
import {
  CARA_CREATE_TABLE_REQUEST_SUCCESS,
  CARA_FORMULA_REQUEST_SUCCESS,
  CARA_REQUEST_SUCCESS,
  CARA_RM_LIST_FILTER_REQUEST_SUCCESS,
  CARA_RM_LIST_REQUEST_SUCCESS,
  CARA_RM_REQUEST_SUCCESS,
  CARA_SENTIMENT_WORD_CLOUD_REQUEST_SUCCESS,
  CARA_TNL_LIST_FILTER_REQUEST_SUCCESS,
  CARA_TNL_LIST_REQUEST_SUCCESS,
  CLEAR_RM_LIST,
  CLEAR_RM_LIST_FILTER,
  CLEAR_TNL_LIST,
  CLEAR_TNL_LIST_FILTER,
  GLOBAL_ERROR,
  CLEAR_CLEAR_PAGE,
    CARA_DOPPELGANGERS_REQUEST_SUCCESS,
} from './types';
import {catchFunction, getConfig} from "./utils";

export function caraRequest(data) {
  return (dispatch) => {
    return axiosInstance
        .post(`/rest_api/cara/raw-material-combinations/xls-rm-by-ingredients`, data, getConfig())
        .then((response) => { return response.data; })
        .then((response) => {
          dispatch({
            type: CARA_REQUEST_SUCCESS,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: caraRequest(data)
          })
        });
  };
}

export function caraRMRequest(data) {
  return (dispatch) => {
    return axiosInstance
        .post(`/rest_api/cara/raw-material-combinations/list-rm-by-ingredients`, data, getConfig())
        .then((response) => { return response.data; })
        .then((response) => {
          dispatch({
            type: CARA_RM_REQUEST_SUCCESS,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: caraRMRequest(data)
          })

          return error
        });
  };
}

export function caraDoppelgangersRequest(data) {
  return (dispatch) => {
    return axiosInstance
        .post(`/rest_api/cara/product-dopelgangers`, data, getConfig())
        .then((response) => { return response.data; })
        .then((response) => {
          dispatch({
            type: CARA_DOPPELGANGERS_REQUEST_SUCCESS,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: caraDoppelgangersRequest(data)
          })

          return error
        });
  };
}
export function caraDoppelgangersDetail(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/cara/absa/index`, data, getConfig())
      .then((response) => { return response.data; })
      .then((response) => {
        dispatch({
          type: CARA_DOPPELGANGERS_REQUEST_SUCCESS,
          data: response,
        });

        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: caraDoppelgangersDetail(data)
        })

        return error
      });
  };
}

export function caraFormulaRequest(data) {
  return (dispatch) => {
    return axiosInstance
        .post(`/rest_api/cara/raw-material-combinations/alg3`, data, getConfig())
        .then((response) => { return response.data; })
        .then((response) => {
          dispatch({
            type: CARA_FORMULA_REQUEST_SUCCESS,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: caraFormulaRequest(data)
          })
        });
  };
}

export function caraRMListRequest(data) {
    const requestData = {
      offset: 0,
      limit: data.limit,
      sort: data.sort,
      id: data.rmList,
      rm_type: data.rm_type || ''
    }

    return (dispatch) => {
        return axiosInstance
            .post(`/rest_api/formulator/compound_ingredient/list`, requestData, getConfig())
            .then((response) => { return response.data; })
            .then((response) => {
                dispatch({
                    type: CARA_RM_LIST_REQUEST_SUCCESS,
                    data: response,
                });

                return response;
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: caraRMListRequest(data)
                })
            });
    };
}

export function caraTNLListRequest(data) {
  const requestData = {
    offset: 0,
    limit: data.limit,
    sort: data.sort,
    id: data.rmList,
    rm_type: data.rm_type || ''
  }

  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/trade_name_library`, requestData, getConfig())
      .then((response) => { return response.data; })
      .then((response) => {
        dispatch({
          type: CARA_TNL_LIST_REQUEST_SUCCESS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: caraTNLListRequest(data)
        })
      });
  };
}

export function caraRMListFilterRequest(data) {

  const requestData = {
    offset: 0,
    limit: data.limit,
    sort: 'name',
    ingredient_ids: data.ingredient_ids,
    rm_type: data.rm_type || '',
    id: data.rmList,
  }

    return (dispatch) => {
        return axiosInstance
            .post(`/rest_api/formulator/compound_ingredient/list`, requestData, getConfig())
            .then((response) => { return response.data; })
            .then((response) => {
                dispatch({
                    type: CARA_RM_LIST_FILTER_REQUEST_SUCCESS,
                    data: response,
                });

                return response;
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: caraRMListFilterRequest(data)
                })
            });
    };
}

export function caraTNLListFilterRequest(data) {

  const requestData = {
    offset: 0,
    limit: data.limit,
    sort: 'name',
    id: data.rmList,
    rm_type: data.rm_type || '',
    ingredient_ids:  data.ingredient_ids,
  }

  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/trade_name_library`, requestData,
        getConfig()
      )
      .then((response) => { return response.data; })
      .then((response) => {
        dispatch({
          type: CARA_TNL_LIST_FILTER_REQUEST_SUCCESS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: caraTNLListFilterRequest(data)
        })
      });
  };
}

export function caraCreateTableRequest(data) {
  return (dispatch) => {
    return axiosInstance
        .post(`/rest_api/cara/rate-prediction/inci`, data, getConfig())
        .then((response) => { return response.data; })
        .then((response) => {
          dispatch({
            type: CARA_CREATE_TABLE_REQUEST_SUCCESS,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: caraCreateTableRequest(data)
          })
        });
  };
}

export function caraSentimentAnalysisWordCloudRequest(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/cara/sentiment-analysis/wordclouds`, data, { ...getConfig(), responseType: "blob" })
      .then((response) => {
        return URL.createObjectURL(response.data);
      })
      .then((response) => {
        dispatch({
          type: CARA_SENTIMENT_WORD_CLOUD_REQUEST_SUCCESS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          catchFunction({
            error,
            dispatch,
            refreshFunction: caraSentimentAnalysisWordCloudRequest(data)
          })
        } else {
          dispatch({
            type: GLOBAL_ERROR,
            data: JSON.stringify({
              message: `Error: ${error.response.request.status}, ${error.response.request.statusText}`
            }),
          });
        }
      });
  };
}

export function clearCaraRMList() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_RM_LIST,
        });
    };
}

export function clearCaraTNLList() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_TNL_LIST,
    });
  };
}


export function clearCaraRMListFilter() {
  return (dispatch) => {
      dispatch({
          type: CLEAR_RM_LIST_FILTER,
      });
  };
}

export function clearCaraTNLListFilter() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_TNL_LIST_FILTER,
    });
  };
}

export function clearCaraPage() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_CLEAR_PAGE,
        });
    };
}
