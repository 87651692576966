import {
  ADD_ERROR,
  REMOVE_ERROR,
  ADD_NOTICE,
  REMOVE_NOTICE,
  OPEN_PRESS_POPUP,
  CLOSE_PRESS_POPUP,
  SHOW_STANDARD_MESSAGE,
  CLOSE_STANDARD_MESSAGE,
  OPEN_REQUEST_REVIEW_POPUP,
  CLOSE_REQUEST_REVIEW_POPUP,
  OPEN_SUBSCRIPTION_POPUP,
  CLOSE_SUBSCRIPTION_POPUP,
  OPEN_ANDROID_NOTIFY_POPUP,
  CLOSE_ANDROID_NOTIFY_POPUP,
  OPEN_PROMO_BAR,
  CLOSE_PROMO_BAR,
  OPEN_QUESTION_POPUP,
  CLOSE_QUESTION_POPUP
} from '../actions';

const defaultState = {
  errors: [],
  notices: [],
  pressPopup: false,
  showStandardMessage: false,
  standardMessage: '',
  requestReviewPopup: false,
  subscriptionPopup: false,
  androidNotifyPopup: false,
  promoBar: false,
  questionPopup: false
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_ERROR:

      const error = {
        key: action.data.key,
        message: action.data.message
      };

      return {
        ...state,
        errors: [...state.errors, error]
      };

    case REMOVE_ERROR:

      return {
        ...state,
        errors: state.errors.filter(error => error.key !== action.data.key)
      };

    case ADD_NOTICE:

      const notice = {
        key: action.data.key,
        message: action.data.message
      };

      return {
        ...state,
        notices: [...state.notices, notice]
      };

    case REMOVE_NOTICE:

      return {
        ...state,
        notices: state.notices.filter(notice => notice.key !== action.data.key)
      };

    case OPEN_PRESS_POPUP:

      return {
        ...state,
        pressPopup: true
      };

    case CLOSE_PRESS_POPUP:

      return {
        ...state,
        pressPopup: false
      };

    case OPEN_REQUEST_REVIEW_POPUP:

      return {
        ...state,
        requestReviewPopup: true
      };

    case CLOSE_REQUEST_REVIEW_POPUP:

      return {
        ...state,
        requestReviewPopup: false
      };

    case OPEN_SUBSCRIPTION_POPUP:

      return {
        ...state,
        subscriptionPopup: true
      };

    case CLOSE_SUBSCRIPTION_POPUP:

      return {
        ...state,
        subscriptionPopup: false
      };

    case OPEN_ANDROID_NOTIFY_POPUP:

      return {
        ...state,
        androidNotifyPopup: true
      };

    case CLOSE_ANDROID_NOTIFY_POPUP:

      return {
        ...state,
        androidNotifyPopup: false
      };

    case SHOW_STANDARD_MESSAGE:

      return {
        ...state,
        showStandardMessage: true,
        standardMessage: action.message
      }

    case CLOSE_STANDARD_MESSAGE:

      return {
        ...state,
        showStandardMessage: false,
        standardMessage: ''
      }

    case OPEN_PROMO_BAR:

      return {
        ...state,
        promoBar: true
      };

    case CLOSE_PROMO_BAR:
      const hasFixedHeader = document.getElementById('header-fixed');

      if (hasFixedHeader) {
        const gfpAppWrap = document.getElementsByClassName('gfp-app-wrap');
        gfpAppWrap[0].classList.remove('-with-banner');
        gfpAppWrap[0].classList.add('-fixed');
      }

      return {
        ...state,
        promoBar: false
      };

    case OPEN_QUESTION_POPUP:

      return {
        ...state,
        questionPopup: true
      };

    case CLOSE_QUESTION_POPUP:

      return {
        ...state,
        questionPopup: false
      };

    default:
      return state;
  }
}



