import {
  CLEAR_PARTNERSHIPS_RESTRICTIONS,
  GET_RESTRICTION_ANALYZE,
} from '../actions/types';

export default function reducer(state = {isLoggedIn: false, role: false}, action) {
  switch (action.type) {
    case GET_RESTRICTION_ANALYZE:
      return {
          ...state,
          restrictions: action.data,
      };
    case CLEAR_PARTNERSHIPS_RESTRICTIONS:
      return {
        ...state,
        restrictions: [],
      };
      default:
        return state;
  }
}

