import {getTimeSpent} from "../utils/utils";
import {segmentsTrack} from "../utils/trackEvents";

import axiosInstance from './axiosInstance';
import { setShowEditConfirmModal } from './formulator';
import { SET_FEATURE_FLAGS } from './types';
import { catchFunction, getConfig } from './utils';

export const GET_USER_STATUS = 'GET_USER_STATUS';
export const GET_SSO_URL = 'GET_SSO_URL';
export const GET_GOOGLE_AUTH_URL = 'GET_GOOGLE_AUTH_URL';

export function getUserStatus() {
  return dispatch => {
    return axiosInstance.get(`/publicapi/user/status/`)
      .then(response => {
        return response.data;
      })
      .then(response => {
        dispatch({
          type: GET_USER_STATUS,
          data: response
        })
      }).catch(error => {
        console.error(error?.response?.statusText)
      });
  }
}

export function setFeatureFlags(data) {
  return (dispatch) => {
    dispatch({
      data: data,
      type: SET_FEATURE_FLAGS,
    });
  };
}
export function logOut(data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/auth/logout`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: logOut(data),
        });
      });
  };
}

export function signOut(isEditmode) {
  return (dispatch) => {
    const data = JSON.parse(sessionStorage.getItem("user-info-for-verified"));

    segmentsTrack({
      user: data.user,
      name: 'User Logged Out',
      description: 'User Logs Out',
      time_spent: getTimeSpent(),
    })

    if (isEditmode) {
      dispatch(setShowEditConfirmModal(true))
    } else {
      window.localStorage.removeItem('gfp-maintenance-message');
      window.localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString());
      window.localStorage.removeItem('CREDENTIALS_FLUSH');
      window.localStorage.removeItem('login');
      sessionStorage.removeItem('user-info-for-verified');
      window.location.href = '/';
    }
  };
}

export function getOktaLink(loginType) {
  const ssoType = loginType === 'sso' ? '' : `?sso_type=azure`;

  return dispatch => {
    return axiosInstance.get(`/publicapi/user/get_sso_url/${ssoType}`)
        .then(response => {
          return response.data;
        })
        .then(response => {
          dispatch({
            type: GET_SSO_URL,
            data: response
          })

          return response
        }).catch(error => {
          console.error(error?.response?.statusText)
        });
  }
}

export function getGoogleAuthLink(provider) {
  const providerName = provider ? `?provider=${provider}` : '';

  return dispatch => {
    return axiosInstance.get(`/rest_api/social_auth/get_authorization_url${providerName}`)
        .then(response => {
          return response.data;
        })
        .then(response => {
          dispatch({
            type: GET_GOOGLE_AUTH_URL,
            data: response
          })

          return response
        }).catch(error => {
          console.error(error?.response?.statusText)
        });
  }
}
