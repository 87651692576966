export const COMPANY_PERMISSION_OPTIONS = {
  FORMULAS: 'formulator',
  FORMULA_SPECIFICATION: 'specification',
  FORMULA_NOTES: 'formula_note',
  FORMULA_STATUS: 'formula_status',
  FORMULA_LOCK: 'lock_formula',
  FORMULA_PHASE: 'phase',
  FORMULA_COST: 'cost',
  FUNCTION: 'function',
  ISO16128: 'iso16128',
  PARTNERSIPS: 'partnerships',
  RM_CREATE_EDIT: 'compound_formulator',
  RM_STATUS: 'raw_material_status',
  RM_LOCK: 'lock_raw_material',
  REPLACEMENT_GROUP: 'replacement_group',
  QQ_XLS: 'generate_qq_xls_report',
  BENEFITS_XLS: 'benefits_report',
  REGULATORY_COMPLIANCE_XLS: 'regulatory_compliance_report',
  BATCH_CARD_XLS: 'generate_xls_batch_card_report',
  FORMULATED_WITHOUT_XLS: 'formulated_without_report',
  XLS_COMPOUND: 'generate_xls_compound_report',
  SCORECARD_PDF: 'generate_scorecard_report',
  FULL_PRODUCT_SCORECARD_PDF: 'generate_product_report',
  MATERIAL_DATASHEET_PDF: 'generate_material_datasheet_report',
  CORRECTIVE_ACTIONS_PDF: 'generate_corrective_actions_report',
  RAW_MATERIAL_PDF: 'generate_raw_material_report',
  RAW_MATERIAL_COMPLIANCE_PDF: 'generate_raw_material_compliance_report',
  SHOW_BENEFITS_BLOCK: 'show_benefits_block',
  CARA_ACCESS: 'cara_access',
  CARA_REVERSE_INGINEERING: 'cara_raw_material_combinator',
  CARA_PRODUCT_DOPELGANGERS: 'cara_product_dopelgangers',
  BLACKLIST: 'blacklist',
  PRODUCT_LISTING_SPL_GENERATOR: 'product_listing_spl_generator',
  ELF_CUSTOM_FORMULA_CARD: 'formula_card_report',
  ELF_CUSTOM_SPEC_SHEET: 'product_specs_report',
  AUTOMATED_IMPURITIES: 'automated_impurities',
  ALLERGEN_GROUPS: 'allergen_groups',
  TNL: 'inventory',
  IN_MARKET: 'consumer_dbs',
  SMART_FORMULA: 'smart_formula',
}

export const USER_PERMISSION_OPTIONS = {
  FORMULA_CREATE_DUPLICATE: 'formula.create',
  FORMULA_EDIT_NOTES: 'formula_note.edit',
  FORMULA_DELETE: 'formula.delete',
  FORMULA_DELETE_OWN: 'formula.delete.own',
  RM_CREATE: 'raw_material.create',
  RM_EDIT: 'raw_material.edit',
  RM_DELETE: 'raw_material.delete',
  REPLACEMENT_GROUP_EDIT: 'replacement_group.edit',
  FORMULA_EDIT_SPECS: 'formula_specification.edit',
  PARTNERSSHIP_VIEW: 'partnerships.edit',
  XLS_QQ_REPORT: 'xls_qq_report.generate',
  XLS_BENEFITS_REPORT: 'xls_benefits_report.generate',
  XLS_COMPIANCE_REPORT: 'xls_compliance_report.generate',
  XLS_FORMULATED_WITHOUT_REPORT: 'xls_formulated_without_report.generate',
  XLS_RAW_MATERIAL_COMPLIANCE_REPORT: 'xls_raw_material_compliance_report.generate',
  XLS_BATCH_CARD_REPORT: 'xls_batch_card_report.generate',
  PDF_LIMITED_PRODUCT_SCORECARD_REPORT: 'pdf_product_scorecard_report.generate',
  PDF_MATERIAL_DATASHEET_REPORT: 'pdf_material_datasheet_report.generate',
  PDF_CORRECTIVE_ACTIONS_REPORT: 'pdf_corrective_actions_report.generate',
  PDF_FULL_PRODUCT_SCORECARD_REPORT: 'pdf_full_product_scorecard_report.generate',
  PDF_FULL_RM_REPORT: 'pdf_full_raw_material_report.generate',
  PDF_LIMITED_RM_REPORT: 'pdf_limited_raw_material_report.generate',
  PRIVATE_CHEMICAL_POLICE: 'private_chemical_police.edit',
  FORMULA_LOCK_UNLOCK: 'formula.lock',
  RM_LOCK_UNLOCK: 'raw_material.lock',
  INGREDIENT_SEARCH: 'ingredient.search',
  CENTRIC_SYNCHRONIZE_FORMULA: 'centric.synchronize_formula',
  CENTRIC_SYNCHRONIZE_RM: 'centric.synchronize_raw_material',
  ELF_CARD_REPORT: 'elf.card_report',
  ELF_SPEC_SHEET: 'elf.spec_sheet',
  RM_EDIT_STATUS: 'raw_material.status',
  REGULATORY_PRESETS_EDIT: 'regulatory_presets.edit',
  FORMULA_EDIT_STATUS: 'formula.status',
  CARA_PRODUCT_RATE_PREDICT: 'cara_product_rate_predict',
  CARA_REVERSE_INGINEERING: 'cara.reverse_engineering',
  CARA_PRODUCT_DOPELGANGERS: 'cara.product_dopelgangers',
  INMARKET_SPL: 'in_market.spl',
  FORMULAS: 'formulas.access',
  RM: 'raw_materials.access',
  PARTNERSHIPS: 'partnerships.access',
  IN_MARKET: 'in-market.access',
  REPORTS: 'reports.access',
  TNL: 'tnl.access'
}

export const FORMULAS = 'Formulas'
export const PARTNERSSHIPS = 'Partnerships'
export const RAWMATERIALS = 'RawMaterials'
export const INMARKET = 'InMarket'
export const REPORTS = 'Reports'
export const CARA = 'Cara'
export const CUSTOM_FEATURES = 'CustomFeatures'

export const permissionsMap = {
  [FORMULAS]: [
    USER_PERMISSION_OPTIONS.FORMULA_CREATE_DUPLICATE,
    USER_PERMISSION_OPTIONS.FORMULA_LOCK_UNLOCK,
    USER_PERMISSION_OPTIONS.FORMULA_EDIT_STATUS,
    USER_PERMISSION_OPTIONS.FORMULA_EDIT_NOTES,
    USER_PERMISSION_OPTIONS.FORMULA_EDIT_SPECS,
  ],
  [PARTNERSSHIPS]: [
    USER_PERMISSION_OPTIONS.PARTNERSSHIP_VIEW,
  ],
  [RAWMATERIALS]: [
    USER_PERMISSION_OPTIONS.RM_CREATE,
    USER_PERMISSION_OPTIONS.RM_DELETE,
    USER_PERMISSION_OPTIONS.RM_LOCK_UNLOCK,
    USER_PERMISSION_OPTIONS.RM_EDIT_STATUS,
  ],
  [INMARKET]: [
    USER_PERMISSION_OPTIONS.INMARKET_SPL,
  ],
  [REPORTS]: [
    USER_PERMISSION_OPTIONS.XLS_QQ_REPORT,
    USER_PERMISSION_OPTIONS.XLS_COMPIANCE_REPORT,
    USER_PERMISSION_OPTIONS.XLS_RAW_MATERIAL_COMPLIANCE_REPORT,
    USER_PERMISSION_OPTIONS.XLS_BATCH_CARD_REPORT,
    USER_PERMISSION_OPTIONS.PDF_LIMITED_PRODUCT_SCORECARD_REPORT,
    USER_PERMISSION_OPTIONS.PDF_MATERIAL_DATASHEET_REPORT,
    USER_PERMISSION_OPTIONS.PDF_CORRECTIVE_ACTIONS_REPORT,
    USER_PERMISSION_OPTIONS.PDF_LIMITED_RM_REPORT,
  ],
  [CARA]: [
    USER_PERMISSION_OPTIONS.CARA_PRODUCT_DOPELGANGERS,
    USER_PERMISSION_OPTIONS.CARA_REVERSE_INGINEERING,
  ],
  [CUSTOM_FEATURES]: [
    USER_PERMISSION_OPTIONS.PRIVATE_CHEMICAL_POLICE,
    USER_PERMISSION_OPTIONS.REGULATORY_PRESETS_EDIT,
    USER_PERMISSION_OPTIONS.ELF_CARD_REPORT,
    USER_PERMISSION_OPTIONS.ELF_SPEC_SHEET,
  ],
};
