import React from 'react';
import ReactDOM from "react-dom";

import Header from '../../components/Header'

const HeaderPortal = (props) => ReactDOM.createPortal(
  <Header {...props}/>,
  document.getElementById('header'),
);

export default HeaderPortal;
