import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from "actions/types";


const initialState = {
  condition: false,
  msg: '',
  errorSnackbar: false,
  withBtn: false,
  btnText: '',
  url: '',
  showSnackbar: false,
  showSnackbarError: false,
  showBtn: false,
  snackBarMsg: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SNACKBAR:
            return {
        ...state,
        condition: action.payload.condition,
        msg: action.payload.msg,
        errorSnackbar: action.payload.errorSnackbar,
        withBtn: action.payload.withBtn,
        btnText: action.payload.btnText,
        url: action.payload.url,
        showSnackbar: action.payload.condition,
        showSnackbarError: action.payload.errorSnackbar && action.payload.condition,
        showBtn: action.payload.withBtn,
        ...(action.payload.msg.length && { snackBarMsg: action.payload.msg }),
        ...(action.payload.withBtn && { btnText: action.payload.btnText, btnUrl: action.payload.url }),
      };
    case CLOSE_SNACKBAR:
      return initialState;
    default:
      return state;
  }
};

export default reducer;