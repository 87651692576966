import {
  CREATE_RAW_MATERIALS,
  GET_ALL_RAW_MATERIALS,
  GET_RAW_MATERIAL, GET_RAW_MATERIAL_FILES,
  REQUEST_REVIEW_RAW_MATERIALS,
  REQUEST_REVIEW_RM,
  UPDATE_COMPOUND_INGREDIENT_STATUS,
  UPDATE_RAW_MATERIAL_STATUS,
  UPDATE_RAW_MATERIALS,
} from '../actions/types';
import {modifyReviewRequested} from "../utils/modifyReviewRequested";

export default function reducer(
  state = { isLoggedIn: false, role: false },
  action,
) {
  switch (action.type) {
    case GET_RAW_MATERIAL:
      return {
        ...state,
        compoundIngredient: action.data,
        // compoundIngredientParam: {
        //   data: action.data?.ingredient_params?.filter(
        //     (ing) => ing.param_type === 'inci',
        //   ),
        // },
        // compoundIngredientImpurity: {
        //   data: action.data?.ingredient_params?.filter(
        //     (ing) => ing.param_type === 'impurity',
        //   ),
        // },
        // compoundIngredientAllergen: {
        //   data: action.data?.ingredient_params?.filter(
        //     (ing) => ing.param_type === 'allergen',
        //   ),
        // },
        error: '',
      };
    case GET_RAW_MATERIAL_FILES:

      return {
        ...state,
        compoundIngredients: {
          ...state.compoundIngredients,
          files: action.data.data,
        },
      }
    case GET_ALL_RAW_MATERIALS:
      return {
        ...state,
        compoundIngredients: action.data,
      };
    case UPDATE_RAW_MATERIALS:
      return {
        ...state,
        compoundIngredient: action.data,
      }
    case CREATE_RAW_MATERIALS:
      return {
        ...state,
        compoundIngredient: action.data,
      }
    case UPDATE_RAW_MATERIAL_STATUS:
      return {
        ...state,
        compoundIngredient: {
          ...state.compoundIngredients,
          status: action.data.status,
        },
      };
    case REQUEST_REVIEW_RAW_MATERIALS:
      return {
        ...state,
        compoundIngredientParam: {
          data: state.compoundIngredientParam?.data?.map(
            (item) => modifyReviewRequested(item, action.data)
          ) || []
        },
        compoundIngredientImpurity: {
          data: state.compoundIngredientImpurity?.data?.map(
            (item) => modifyReviewRequested(item, action.data)
          ) || []
        },
        compoundIngredientAllergen: {
          data: state.compoundIngredientAllergen?.data?.map(
            (item) => modifyReviewRequested(item, action.data)
          ) || []
        },
        compoundIngredientIncidental: {
          data: state.compoundIngredientIncidental?.data?.map(
            (item) => modifyReviewRequested(item, action.data)
          ) || []
        }
      }
    default:
      return state;
  }
}
