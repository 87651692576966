import React, { useRef, useState } from 'react';
import classNames from 'classnames'

import Button from "../Button_v2";

import styles from "./index.module.scss";

const RightSidebar = (props) => {
  const { isOpen, closeSidebar, headerContent, content, headerClassName } = props;
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const contentRef = useRef(null);

  const handleScroll = (event) => {
   if (event.currentTarget.scrollTop > 0) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  }

  return isOpen && (
    <div className={classNames(styles['sidebar'])}>
      <div className={classNames(styles['header'], styles[headerClassName], 'right-sidebar')}>
        <div className={styles['header__content']}>{headerContent}</div>
        <Button className={styles['header__close']} onClick={closeSidebar} />
      </div>
      <div onScroll={handleScroll} className={classNames(styles['main-content'])}>
        <div ref={contentRef} className={styles['contentWrapper']}>
          {content}
        </div>
        { showScrollToTop && (
          <button
            onClick={() => contentRef.current.scrollIntoView({top: 0, behavior: 'smooth'})}
            className={styles['sidebar__scrollTop']}
          />
        )}
      </div>
    </div>
  )
}

export default RightSidebar;
