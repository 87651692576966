import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { catchFunction, getConfig } from 'actions/utils';
import axiosInstance from '../../../actions/axiosInstance';

import { updateTrashFormulatorFormulas } from '../FormulatorFormulasUpdate/slice';
import { deleteTrashFormulatorProducts } from '../FormulatorProductsDelete/slice';
import { deleteTrashFormulatorProductsBulk } from '../FormulatorProductsDeleteBulk/slice';

export const initialState = {
  formulatorProducts: undefined,
  error: undefined,
  isLoading: false,
};

export const getTrashFormulatorProducts = createAsyncThunk(
  'get_formulator_products',
  async (param, { dispatch, rejectWithValue }) => {
    const { folder_id, sort } = param;
    const id = folder_id ? `?folder_id=${folder_id}` : '';
    const sortQuery = sort ? (id ? `&sort=${sort}` : `?sort=${sort}`) : '';

    try {
      const response = await axiosInstance.get(
        `/rest_api/trash/formulator/products${id}${sortQuery}`,
        getConfig(),
      );

      return response.data;
    } catch (error) {
      catchFunction({
        error,
        dispatch,
        refreshFunction: getTrashFormulatorProducts(),
      });

      return rejectWithValue(error.message);
    }
  },
);

export const formulatorProductsTrash = createSlice({
  name: 'get_formulator_products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrashFormulatorProducts.pending, (state) => {
        state.isLoading = true;
        state.formulatorProducts = undefined;
        state.error = undefined;
      })
      .addCase(getTrashFormulatorProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.formulatorProducts = action.payload;
      })
      .addCase(getTrashFormulatorProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateTrashFormulatorFormulas.fulfilled, (state, action) => {
        state.isLoading = false;
        state.formulatorProducts = action.payload;
      })
      .addCase(deleteTrashFormulatorProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.formulatorProducts = state.formulatorProducts?.filter((trash) => {
          return trash.id !== action.payload.id;
        });
      })
      .addCase(deleteTrashFormulatorProductsBulk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.formulatorProducts = state.formulatorProducts?.filter((trash) => {
          return trash.id !== action.payload.id;
        });
      });
  },
});

export default formulatorProductsTrash.reducer;
