import axiosInstance from './axiosInstance';
import { catchFunction, getConfig } from './utils';
import { GET_MAINTENANCE_MESSAGES } from './types';

export function getMaintenanceMessages() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/maintenance-messages`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_MAINTENANCE_MESSAGES,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getMaintenanceMessages(),
        })
      });
  };
}
