/* eslint-disable no-unused-vars, react-hooks/exhaustive-deps*/

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import config from 'config';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { connect, shallowEqual, useSelector } from 'react-redux';
import Cookies from "universal-cookie";

import FormulatorMenu from '../../pages/FormulatorAdmin/FormulatorMenu';
import store from '../../store';
import {
  getUserProfile,
  getNotifications,
  setShowMaintenanceMessage,
  markAllNotificationsRead,
  signOut, logOut,
} from '../../actions';
import Button from '../Button_v2';
import Logo from '../Logo';
import { Section, Container } from '../Layout';
import RightSidebar from '../Sidebar';
import UserGuidContent from '../Sidebar/UserGuidContent';
import NotificationsContent from '../Sidebar/NotificationsContent';
import { getApiBaseSocketUrl } from "../../utils/utils"
import { CutTextRender } from "../../pages/FormulatorAdminProfile/components/utils/cutTextRender";

import styles from './index.module.scss';

const cookies = new Cookies(),
    { sessionStorage } = window;

const HeaderFA = (props) => {
  const {
    location: { pathname },
    history,
    showMaintenanceMessage,
    notifications,
    formulator,
    updateBrowser,
    user,
    featureFlags,
    windowSize,
  } = props;
  const isFormulator = history.location.pathname.indexOf('/fa/') !== -1 || history.location.pathname.indexOf('/raw-material-publish') !== -1;
  const currentUser = formulator.user;
  const search = history.location.search;
  const companyPermissions = formulator.companyPermissions;
  const isSupplier = !!companyPermissions?.find((item) => item.name === 'supplier_basic_account');
  let str = `${currentUser?.profile_data?.name || ''} ${
    currentUser?.profile_data?.lastname || ''
  }`;
  let acronym = str
    ? str
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '')
        .slice(0, 2)
    : '';

  const [showMenuCondition, setShowMenuCondition] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isUserNotificationsOpen, setIsUserNotificationsOpen] = useState(false);
  const [isUserGuidOpen, setIsUserGuidOpen] = useState(false);
  const [allNotification, setAllNotifications] = useState([]);
  const [showGlobalMessage, setShowGlobalMessage] = useState(window.location.pathname.includes(isSupplier ? '/fa/raw-material': '/fa/formulas') && !cookies.get("gfp-global-naming-message"));
  const maintenanceMessages = useSelector(state => state.formulator?.maintenanceMessages || [], shallowEqual);

  const currentMaintenanceMessage = useMemo(() => {
    const currentTimestamp = new Date();

    return maintenanceMessages.find((item) => {
      const startDate = new Date(item?.start_at);
      const endDate = new Date(item?.end_at);

      return (!item?.start_at || currentTimestamp >= startDate) &&
        (!item?.end_at || currentTimestamp <= endDate);
    });
  }, [maintenanceMessages]);

  // Sockets start
  //
  const getSocketUrl = useCallback(() => {
    if (featureFlags?.notifications?.enabled) {
      const user = JSON.parse(sessionStorage.getItem('user-info-for-verified'));
      const token = user?.access;
      const mainSocketUrl = getApiBaseSocketUrl();

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(
              `${mainSocketUrl}?token=${token}`,
          );
        }, 2000);
      });
    }

    return null

  }, []);

  const [messageHistory, setMessageHistory] = useState([]);

  const [socketUrl, setSocketUrl] = useState(getSocketUrl);

  const { lastMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
    onMessage(message: ParentMessage) {
      try {
        JSON.parse(message.data);
      } catch (e) {
        return message;
      }
      const newNotification = JSON.parse(message.data);

      if (newNotification.id) {
        const newarr = {
          notification: newNotification,
        };
        setAllNotifications([newarr, ...allNotification]);
      }
    },
    onClose: () => {
      setSocketUrl(getSocketUrl);
    },
  });



  // Sockets end
  //
  useEffect(() => {
    const isNew = JSON.parse(sessionStorage.getItem('is-new-user'));
    store.dispatch(getUserProfile());

    if (featureFlags?.notifications?.enabled && !allNotification?.length) {
      store.dispatch(getNotifications()).then((res) => {
        setAllNotifications(res?.data);
      });
    }

    if (isNew) {
      toggleRightSidebar(true);
    }

    if (featureFlags?.notifications?.enabled && lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    store.dispatch(setShowMaintenanceMessage(
      featureFlags?.display_maintenance_message?.enabled &&
      currentMaintenanceMessage?.id &&
      !window.localStorage.getItem('gfp-maintenance-message')
    ));
  }, [currentMaintenanceMessage, featureFlags])

  useEffect(() => {
    const gfpAppWrap = document.getElementsByClassName('gfp-app-wrap');
    gfpAppWrap[0].classList.remove('-fixed');

    if (!featureFlags?.notifications?.enabled) {
      store.dispatch(getNotifications()).then((res) => {
        setAllNotifications(res?.data);
      });
    }

  }, [])

  useEffect(() => {
    setShowGlobalMessage(window.location.pathname.includes(isSupplier ? '/fa/raw-material': '/fa/formulas') && !cookies.get("gfp-global-naming-message"))
  }, [window.location.pathname, isSupplier])
  const showMenu = (event) => {
    event.preventDefault();

    setShowMenuCondition(true);

    if (!showMenuCondition) {
      document.addEventListener('click', closeMenu);
    }
  };

  const closeMenu = () => {
    setShowMenuCondition(false);
    document.removeEventListener('click', closeMenu);
  };

  const logout = async () => {
    const { formulaEditmode, editMode } = props

    if(formulaEditmode){
      store.dispatch(signOut(formulaEditmode, history))
    } else {
      await store.dispatch(logOut({auto_logout: false}))
      localStorage.removeItem('AUTO_LOGOUT');
      localStorage.removeItem('user-info-for-verified')
      sessionStorage.removeItem('email')
      store.dispatch(signOut(editMode, history))
    }

    window.sessionStorage.removeItem('Overdue')
  };

  const toggleMenu = () => {
    if (!isOpenMenu) {
      setIsOpenMenu(true);
      document.addEventListener('click', closeMainMenu);
    }
  };

  const closeMainMenu = () => {
    setIsOpenMenu(false);
    document.removeEventListener('click', closeMainMenu);
  };

  const toggleRightSidebar = (state) => {
    if (state) {
      window.document.body.style.overflow = 'hidden';
    } else {
      window.document.body.style.overflow = '';

      if (JSON.parse(sessionStorage.getItem('is-new-user'))) {
        sessionStorage.removeItem('is-new-user');
      }
    }

    setIsUserGuidOpen(state);
    setIsUserNotificationsOpen(false);
  };

  const toggleRightSidebarNotifications = (state) => {
    window.document.body.style.overflow = state ? 'hidden' : '';
    setIsUserGuidOpen(false);
    setIsUserNotificationsOpen(state);

    if (!state) {
      store.dispatch(markAllNotificationsRead()).then(() => {
        setAllNotifications(
          allNotification?.map((notification) => {
            notification.is_read = true;

            return notification;
          }),
        );
      });
    }
  };

  const unreadNotifications = allNotification?.filter(
    (notification) => !notification.is_read,
  );
  const headerElement = document.getElementById('header');

  return (
    <React.Fragment>
      <Section
        id="header-top"
        renderAs="header"
        className={classNames(
          styles['header'],
          config.analytic.classes.header,
          {
            [styles['header--home']]: pathname === '/',
          },
        )}
      >
        <Container className={styles['header__inner']}>
          <div
            className={styles['header__burger-container']}
            disabled={updateBrowser}
          >
            {((!isFormulator || windowSize.width < 768) && search !== '?documents=true') && (
              <div
                className={styles['header__burger']}
                onClick={() => toggleMenu()}
                onKeyDown={() => toggleMenu()}
              />
            )}
            <Logo className={styles['header__logo']} />
            {!!companyPermissions?.find((item) => item.name === 'supplier_basic_account') && (
              <div className={styles['header__label']}>SUPPLIER - FREE</div>
            )}
          </div>

          <div className={styles['header__right']}>
            <Button
              onClick={() => toggleRightSidebar(!isUserGuidOpen)}
              disabled={updateBrowser}
              className={classNames(
                styles['header__account-tutorial'],
                isUserGuidOpen && styles['header__account-tutorial-active'],
              )}
            />
              <Button
                disabled={updateBrowser}
                onClick={() =>
                  toggleRightSidebarNotifications(!isUserNotificationsOpen)
                }
                className={classNames(
                  'notifications-btn',
                  styles['header__account-notification'],
                  isUserNotificationsOpen &&
                    styles['header__account-notification-active'],
                  unreadNotifications?.length && styles['-unread'],
                )}
              />
            {/*<div>{connectionStatus}</div>*/}
            <div className={`${styles['header__account-divider']}`} />
            <Button
              btnStyle="secondary"
              onClick={showMenu}
              className={styles['header__account-btn']}
            >
              {acronym}
            </Button>

            {showMenuCondition ? (
              <div className={styles['header__menus']}>
                <div className={styles['header__menus-info']}>
                  <div className={styles['header__account-btn']}>{acronym}</div>
                  <div className={styles['header__account-wrapper']}>
                    <div className={styles['header__account-name']}>
                      <CutTextRender text={str} bottom={true} />
                    </div>
                    <div className={styles['header__account-email']}>
                      <CutTextRender text={currentUser?.email} bottom={true} />
                    </div>
                  </div>
                </div>
                <div className={styles['header__menus-menu']}>
                  <Link
                    to="/fa-profile/details"
                    className={
                      updateBrowser
                        ? styles['header__menus-menu-item-disabled']
                        : styles['header__menus-menu-item']
                    }
                  >
                    Account Settings
                  </Link>
                  {/*<Link to="/" className={styles['header__menus-menu-item']}>*/}
                  {/*  Get Help*/}
                  {/*</Link>*/}
                  <Link
                    to="/f_privacy_policy"
                    className={
                      updateBrowser
                        ? styles['header__menus-menu-item-disabled']
                        : styles['header__menus-menu-item']
                    }
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                </div>
                <Button
                  className={classNames(
                    styles['header__menus-menu-item'],
                    styles['-divided'],
                  )}
                  onClick={logout}
                >
                  Sign Out
                </Button>
              </div>
            ) : null}
          </div>
        </Container>
      </Section>

      {showMaintenanceMessage && (
        <Section className={styles['header__message']}>
          <Container className={styles['header__message-container']}>
            <div
              className={classNames(
                styles['header__message-content'],
                styles['header__message-content-maintenance'],
              )}
            >
              <div className={styles['header__message-maintenanceIcon']} />
              <div
                className={styles['header__message-text']}
                dangerouslySetInnerHTML={{
                  __html: currentMaintenanceMessage?.body?.replaceAll('\n', '<br/>'),
                }}
              />
            </div>
            <Button onClick={() => {
              if (showGlobalMessage) {
                headerElement.classList.remove('-with-double-global-messages');
                headerElement.classList.add('-with-global-message');
              } else {
                headerElement.classList.remove('-with-global-message');
              }

              window.localStorage.setItem('gfp-maintenance-message', '1');
              store.dispatch(setShowMaintenanceMessage(false));
            }}>Ok, I understand</Button>
          </Container>
        </Section>
      )}

      {showGlobalMessage ?
        <Section
          className={classNames(
            styles['header__message'],
            showMaintenanceMessage && styles['header__message-second'],
          )}
        >
          <Container className={styles['header__message-container']}>
            <div className={styles['header__message-content']}>
              In order to ensure accuracy in Good Face,{' '}
              <b>please specify INCI names when entering ingredients</b>. Broad
              botanical names like "Green Tea" will match the most common INCI
              name, which may or may not be the intended ingredient. Group-level
              names like "paraben" can represent many INCIs and will be excluded
              from compliance screening.
            </div>
            <Button onClick={() => {
              if (showMaintenanceMessage) {
                headerElement.classList.remove('-with-double-global-messages');
                headerElement.classList.add('-with-global-message');
              } else {
                headerElement.classList.remove('-with-global-message');
              }

              cookies.set('gfp-global-naming-message', 1, {maxAge: 86400 * 365});
              setShowGlobalMessage(false);
            }}>Ok, I understand</Button>
          </Container>
        </Section> : null
      }

      <FormulatorMenu
        pageName="formulas"
        isHeaderMenu
        isMenuOpen={isOpenMenu}
        featureFlags={featureFlags}
        isInMarketFeatureFlag={featureFlags?.in_market_products?.enabled}
        user={props.formulator?.user}
        companyPermissions={props.formulator?.companyPermissions}
      />
      <RightSidebar
        isOpen={isUserGuidOpen}
        closeSidebar={() => toggleRightSidebar(false)}
        headerContent={
          <>
            <div className={styles['sidebar-header__main']}>
              Getting Started
            </div>
            <div className={styles['sidebar-header__secondary']}>
              Enter your first product formula & share with a partner
            </div>
          </>
        }
        content={UserGuidContent()}
      />
      <RightSidebar
        isOpen={isUserNotificationsOpen}
        closeSidebar={() => toggleRightSidebarNotifications(false)}
        headerClassName="-size-m"
        headerContent={
          <>
            <div
              className={classNames(
                styles['sidebar-header__main'],
                'user-notifications-header',
              )}
            >
              Notifications
            </div>
          </>
        }
        content={NotificationsContent(allNotification)}
      />
    </React.Fragment>
  );
};

export default connect((state) => ({
  formulator: state.formulator,
  user: state.formulator.user,
  notifications: state.formulator.notifications,
  formulaEditmode: state.formulator.formulaEditmode,
  showMaintenanceMessage: state.formulator.showMaintenanceMessage,
  editMode: state.formulator.editMode,
  featureFlags: state.user.featureFlags,
}))(withRouter(HeaderFA));
