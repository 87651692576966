import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { catchFunction, getConfig } from 'actions/utils';
import axiosInstance from '../../../actions/axiosInstance';


export const initialState = {
  formulatorProducts: undefined,
  error: undefined,
  isLoading: false,
};
export const updateTrashFormulatorFormulas = createAsyncThunk(
  'update_formulator_formulas',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/rest_api/trash/formulator/formulas`,
          data,
          getConfig(),
      );

      return response.data;
    } catch (error) {
      catchFunction({
        error,
        dispatch,
        refreshFunction: updateTrashFormulatorFormulas(data),
      });

      return rejectWithValue(error.message);
    }
  },
);

export const updateFormulatorFormulasTrash = createSlice({
  name: 'update_formulator_formulas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateTrashFormulatorFormulas.pending, (state) => {
        state.isLoading = true;
        state.formulatorProducts = undefined;
        state.error = undefined;
      })
      .addCase(updateTrashFormulatorFormulas.fulfilled, (state, action) => {
        state.isLoading = false;
        state.formulatorProducts = action.payload;
      })
      .addCase(updateTrashFormulatorFormulas.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default updateFormulatorFormulasTrash.reducer;
