import axiosInstance from './axiosInstance';

export const GET_PRODUCT = 'GET_PRODUCT';


export function getProduct(product) {
  return dispatch => {
    return axiosInstance.get(`/publicapi/product/${product}`)
      .then(response => response.data)
      .then(response => {
        if (response.success) {
          dispatch({
            type: GET_PRODUCT,
            data: response.data
          })
        }
      });
  }
}
