import React from 'react';
import {connect} from "react-redux";
import {closeStandardMessage} from "actions";


import Modal from "../Modal";
import Heading from "../Heading_v2";

import styles from './index.module.scss';

const ShowStandardMessage = (props) => {

  const {isOpen, standardMessage, closeStandardMessage} = props

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeStandardMessage}
    >
      <div className={styles['modal']}>
          <div className={styles['success-msg']}>
            <Heading as="h4">
              {standardMessage}
            </Heading>
          </div>
      </div>

    </Modal>
  );
}


export default connect(
  state => ({
    isOpen: state.general.showStandardMessage,
    standardMessage: state.general.standardMessage,
  }),
  dispatch => ({
    closeStandardMessage: () => dispatch(closeStandardMessage())
  })
)(ShowStandardMessage);
