import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { catchFunction, getConfig } from 'actions/utils';
import axiosInstance from '../../../actions/axiosInstance';

export const initialState = {
  сompoundIngredients: undefined,
  error: undefined,
  isLoading: false,
};

export const deleteTrashCompoundIngredients = createAsyncThunk(
  'delete_compound_ingredients',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/rest_api/trash/compound_ingredients/${id}`, getConfig())

      return response.data;
    } catch (error) {
      catchFunction({
        error,
        dispatch,
        refreshFunction: deleteTrashCompoundIngredients(id),
      });

      return rejectWithValue(error.message);
    }
  },
);

export const deleteCompoundIngredientsTrash = createSlice({
  name: 'delete_compound_ingredients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteTrashCompoundIngredients.pending, (state) => {
        state.isLoading = true;
        state.сompoundIngredients = undefined;
        state.error = undefined;
      })
      .addCase(deleteTrashCompoundIngredients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.сompoundIngredients = action.payload;
      })
      .addCase(deleteTrashCompoundIngredients.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default deleteCompoundIngredientsTrash.reducer;
